import React, { useState } from 'react';
import { Modal, Box, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Button, TablePagination } from '@mui/material';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PrintIcon from '@mui/icons-material/Print'; 
import CloseIcon from '@mui/icons-material/Close';

const FeesByOrderModal = ({ open, handleClose, header, results }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100); // Mostrar 100 filas por página

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const calculateThirdDueTotal = (cupones) => {
    return cupones.reduce((total, cupon) => total + cupon.third_due_amount, 0);
  };

  const exportToExcel = () => {
    const data = results.map(result => ({
      "Número de Contrato": result.contract_header.contract_number,
      "Suscriptor": result.contract_header.subscriber_name,
      "Plan": result.contract_header.plan_name,
      "Grupo": result.contract_header.group_name,
      "Cuotas Emitidas": result.total_cuotas_emitidas,
      "Cuotas Cobradas": result.total_cuotas_cobradas,
      "Cuotas Adeudadas": result.total_cuotas_adeudadas,
      "Cuotas Adelanto": result.total_cuotas_adelanto,
      "Cuota de Inicio": result.cuota_inicio,
      "Total Pagado": result.total_suma_pagada,
      "Total Sin Interés": result.total_suma_sin_interes,
      "Total Puntaje": result.total_suma_puntaje
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "CuotasPorOrden");

    XLSX.writeFile(workbook, "CuotasPorOrden.xlsx");
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    let yOffset = 10;

    doc.setFontSize(12);
    doc.text("Reporte de cuotas por orden", 105, yOffset, { align: 'center' });
    yOffset += 10;

    results.forEach(result => {
      doc.setFontSize(9);

      doc.setFont("helvetica", "bold");
      doc.text("Número de Contrato:", 10, yOffset);
      doc.setFont("helvetica", "normal");
      doc.text(`${result.contract_header.contract_number}`, 45, yOffset);

      doc.setFont("helvetica", "bold");
      doc.text("Suscriptor:", 10, yOffset + 5);
      doc.setFont("helvetica", "normal");
      doc.text(`${result.contract_header.subscriber_name}`, 45, yOffset + 5);

      doc.setFont("helvetica", "bold");
      doc.text("Cuotas Emitidas:", 10, yOffset + 10);
      doc.setFont("helvetica", "normal");
      doc.text(`${result.total_cuotas_emitidas}`, 45, yOffset + 10);

      doc.setFont("helvetica", "bold");
      doc.text("Cuotas Cobradas:", 10, yOffset + 15);
      doc.setFont("helvetica", "normal");
      doc.text(`${result.total_cuotas_cobradas}`, 45, yOffset + 15);

      doc.setFont("helvetica", "bold");
      doc.text("Cuotas Adeudadas:", 10, yOffset + 20);
      doc.setFont("helvetica", "normal");
      doc.text(`${result.total_cuotas_adeudadas}`, 45, yOffset + 20);

      doc.setFont("helvetica", "bold");
      doc.text("Cuotas Adelanto:", 10, yOffset + 25);
      doc.setFont("helvetica", "normal");
      doc.text(`${result.total_cuotas_adelanto}`, 45, yOffset + 25);

      doc.setFont("helvetica", "bold");
      doc.text("Plan:", 130, yOffset);
      doc.setFont("helvetica", "normal");
      doc.text(`${result.contract_header.plan_name}`, 160, yOffset);

      doc.setFont("helvetica", "bold");
      doc.text("Grupo:", 130, yOffset + 5);
      doc.setFont("helvetica", "normal");
      doc.text(`${result.contract_header.group_name}`, 160, yOffset + 5);

      doc.setFont("helvetica", "bold");
      doc.text("Cuota de Inicio:", 130, yOffset + 10);
      doc.setFont("helvetica", "normal");
      doc.text(`${result.cuota_inicio}`, 160, yOffset + 10);

      doc.setFont("helvetica", "bold");
      doc.text("Total Pagado:", 130, yOffset + 15);
      doc.setFont("helvetica", "normal");
      doc.text(`$${result.total_suma_pagada.toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, 160, yOffset + 15);

      doc.setFont("helvetica", "bold");
      doc.text("Total Sin Interés:", 130, yOffset + 20);
      doc.setFont("helvetica", "normal");
      doc.text(`$${result.total_suma_sin_interes.toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, 160, yOffset + 20);

      doc.setFont("helvetica", "bold");
      doc.text("Total Puntaje:", 130, yOffset + 25);
      doc.setFont("helvetica", "normal");
      doc.text(`$${result.total_suma_puntaje.toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, 160, yOffset + 25);

      yOffset += 35;
      if (yOffset > 270) {
        doc.addPage();
        yOffset = 10;
      }

      doc.setDrawColor(0);
      doc.line(10, yOffset - 5, 200, yOffset - 5);
    });

    doc.save('CuotasPorOrden.pdf');
  };

  if (!header || !results || results.length === 0) {
    return null;
  }

  const paginatedResults = results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        maxHeight: '90vh',
        bgcolor: 'background.paper',
        border: '1px solid #ccc',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        overflowY: 'auto',
        fontFamily: 'Arial, sans-serif',
      }}>
        <Button
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            minWidth: 'auto',
            padding: 0,
            color: '#555'
          }}
        >
          <CloseIcon />
        </Button>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={exportToExcel}
            startIcon={<DescriptionOutlinedIcon />}
          >
            Descargar
          </Button>
          <Button
            sx={{ marginLeft: 2 }}
            variant="contained"
            color="primary"
            onClick={exportToPDF}
            startIcon={<PrintIcon />}
          >
            Imprimir
          </Button>
        </Box>
        <Box
          sx={{
            mb: 3,
            textAlign: 'center',
            border: '1px solid',
            p: 2,
            borderRadius: 2,
            borderColor: '#4a90e2'
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: 'bold', color: '#333', mb: 1 }}
          >
            {header.constructora_info}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: '#555', fontStyle: 'italic', mb: 0.5 }}
          >
            Actualizado a la fecha: {header.updated_at_date}
          </Typography>
          <Typography variant="body2" sx={{ color: '#555' }}>
            {header.date_filter_text}
          </Typography>
        </Box>

        <Typography variant="h5" component="h2" sx={{ mb: 3, textAlign: 'center', fontWeight: 'bold', color: '#4a90e2' }}>
          {header.plan_name}
        </Typography>

        <TableContainer component={Paper} sx={{ border: '1px solid #ddd', borderRadius: 2 }}>
          <Table aria-label="fees by order report">
            <TableBody>
              {paginatedResults.map((result, index) => (
                <React.Fragment key={index}>
                  <TableRow sx={{ backgroundColor: '#f4f6f8' }}>
                    <TableCell colSpan={4}>
                      <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                        Órden: {result.contract_header.contract_number}
                      </Typography>
                      <Typography variant="body1" sx={{ color: '#333' }}>
                        Suscriptor: {result.contract_header.subscriber_name}
                      </Typography>
                      <Typography variant="body2" sx={{ color: '#555' }}>
                        Plan: {result.contract_header.plan_name}
                      </Typography>
                      <Typography variant="body2" sx={{ color: '#555' }}>
                        Grupo: {result.contract_header.group_name}
                      </Typography>

                      <Button
                        variant="contained"
                        sx={{ mt: 2 }}
                        onClick={() => toggleExpand(index)}
                      >
                        {expandedIndex === index ? 'Ocultar cupones adeudados' : 'Ver cupones adeudados'}
                      </Button>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant="body2">
                        <strong>Cuotas emitidas:</strong> {result.total_cuotas_emitidas} <br />
                        <strong>Cuotas cobradas:</strong> {result.total_cuotas_cobradas} <br />
                        <strong>Cuotas adeudadas:</strong> {result.total_cuotas_adeudadas} <br />
                        {result.total_cuotas_adelanto > 0 && (
                          <><strong>Cuotas adelanto c/puntaje:</strong> {result.total_cuotas_adelanto}</>
                        )}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography variant="body2">
                        <strong>Inicia en cuota:</strong> {result.cuota_inicio} <br />
                        <strong>Sumando un total de:</strong> ${result.total_suma_pagada.toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} <br />
                        <strong>Sumando un total de (sin intereses):</strong> ${result.total_suma_sin_interes.toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} <br />
                        {result.total_cuotas_adelanto > 0 && (
                          <><strong>Suma total de cupones de puntaje:</strong> ${result.total_suma_puntaje.toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {expandedIndex === index && result.cupones_adeudados.length > 0 && (
                    <>
                      <TableRow>
                        <TableCell colSpan={4}>
                          <TableContainer component={Paper} sx={{ mt: 2 }}>
                            <Table aria-label="cupones adeudados">
                              <TableBody>
                                <TableRow>
                                  <TableCell colSpan={7} align="center" sx={{ backgroundColor: "#333333" }}>
                                    <Typography
                                      variant="body2"
                                      sx={{ fontWeight: 600, marginRight: 4, color: "whitesmoke" }}
                                    >
                                      CUPONES ADEUDADOS
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell><strong>Número Cuota</strong></TableCell>
                                  <TableCell><strong>Fecha 1er Venc.</strong></TableCell>
                                  <TableCell><strong>Importe 1er</strong></TableCell>
                                  <TableCell><strong>Fecha 2do Venc.</strong></TableCell>
                                  <TableCell><strong>Importe 2do</strong></TableCell>
                                  <TableCell><strong>Fecha 3er Venc.</strong></TableCell>
                                  <TableCell><strong>Importe 3er</strong></TableCell>
                                </TableRow>
                                {result.cupones_adeudados.map((cupon, cuponIndex) => (
                                  <TableRow key={cuponIndex}>
                                    <TableCell>{`Cuota: ${cupon.fee_number1} - ${cupon.fee_number2}`}</TableCell>
                                    <TableCell>{cupon.first_due_date}</TableCell>
                                    <TableCell>{`$ ${cupon.first_due_amount.toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</TableCell>
                                    <TableCell>{cupon.second_due_date}</TableCell>
                                    <TableCell>{`$ ${cupon.second_due_amount.toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</TableCell>
                                    <TableCell>{cupon.third_due_date}</TableCell>
                                    <TableCell>{`$ ${cupon.third_due_amount.toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</TableCell>
                                  </TableRow>
                                ))}
                                <TableRow>
                                  <TableCell colSpan={7} align="right" sx={{ backgroundColor: "#333333" }}>
                                    <Typography
                                      variant="body2"
                                      sx={{ fontWeight: 600, marginRight: 4, color: "whitesmoke" }}
                                    >
                                      TOTAL CUPONES ADEUDADOS: ${calculateThirdDueTotal(result.cupones_adeudados).toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </TableCell>
                      </TableRow>
                    </>
                  )}

                  <TableRow>
                    <TableCell colSpan={4} sx={{ borderBottom: '2px solid #ccc', mb: 3 }}></TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          count={results.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Órdenes por página"
          rowsPerPageOptions={[100, 200, 500]}
        />
      </Box>
    </Modal>
  );
};

export default FeesByOrderModal;
