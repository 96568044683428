import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getDebtCoupons = createAsyncThunk(
  "updatedDebt",
  async (filters) => {
    if (filters.group === "") {
      delete filters.group;
    }
    // if (filters.contract_number === "") {
    //   delete filters.contract_number;
    // }
    // Realiza la solicitud al backend con los filtros combinados
    const response = await apiClient.get("updated_debt", {
      params: filters,
    });

    return response.data;
  }
);

export const updateDebtCoupons = createAsyncThunk(
  "updateDebtCoupons",
  async (dataToSend) => {
    // Verifica que el payload tenga la estructura correcta
    if (!dataToSend || !dataToSend.coupons) {
      throw new Error("Payload is missing or incorrect");
    }

    // Envía los datos al backend
    const response = await apiClient.post("updated_debt", dataToSend);

    return response.data;
  }
);

