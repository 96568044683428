import React, { useState } from 'react';
import { Modal, Box, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper, TableHead, Button, TablePagination } from '@mui/material';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';

const DeferredCouponsModal = ({ open, handleClose, header, results }) => {
  // Estado para la página actual y cantidad de filas por página
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(200); // Mostrar 200 cupones por página

  // Cambiar de página
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Cambiar la cantidad de filas por página
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Volver a la primera página cuando cambie la cantidad de filas
  };

  // Exportar a Excel
  const exportToExcel = () => {
    const data = results.map(result => ({
      "Número de Contrato": result.numero_orden,
      "Suscriptor": result.subscriber_name,
      "Cuotas": result.fee_number2 && result.fee_number2 !== 0
        ? `${result.fee_number1} - ${result.fee_number2}`
        : `${result.fee_number1}`,
      "Fecha Primer Vencimiento": result.first_due_date,
      "Porcentaje Diferido": result.porcentage_diferido,
      "Días Diferidos": result.dias_diferido
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "CuponesDiferidos");

    XLSX.writeFile(workbook, "CuponesDiferidos.xlsx");
  };

  // Exportar a PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    let yOffset = 20; // Espaciado inicial desde el borde superior
  
    // Título centrado
    doc.setFontSize(12);
    doc.text("Reporte de Cupones Diferidos", 105, yOffset, { align: 'center' });
    yOffset += 10;
  
    // Encabezados de la tabla
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text("N°", 10, yOffset);
    doc.text("Suscriptor", 25, yOffset);
    doc.text("Cuotas", 120, yOffset);
    doc.text("Primer Venc.", 140, yOffset);
    doc.text("% Dif.", 165, yOffset);
    doc.text("Días Diferidos", 180, yOffset);
    yOffset += 8; // Espacio después de los encabezados
  
    doc.setFont("helvetica", "normal");
  
    // Iterar sobre los resultados para agregar filas a la tabla
    results.forEach((result) => {
      const cuotasText = result.fee_number2 && result.fee_number2 !== 0
        ? `${result.fee_number1} - ${result.fee_number2}`
        : `${result.fee_number1}`;
  
      doc.text(result.numero_orden.toString(), 10, yOffset); // Número de contrato
      doc.text(result.subscriber_name, 25, yOffset); // Suscriptor
      doc.text(cuotasText, 120, yOffset); // Cuotas
      doc.text(result.first_due_date, 140, yOffset); // Primer vencimiento
      doc.text(`${result.porcentage_diferido}%`, 165, yOffset); // Porcentaje diferido
      doc.text(result.dias_diferido.toString(), 180, yOffset); // Días diferidos
  
      yOffset += 8; // Espacio entre filas
  
      // Crear una nueva página si se llega al límite
      if (yOffset > 270) {
        doc.addPage();
        yOffset = 20; // Restablecer espaciado para la nueva página
      }
    });
  
    doc.save('CuponesDiferidos.pdf'); // Guardar el PDF
  };
  

  if (!header || !results || results.length === 0) {
    return null;
  }

  // Calcular los datos para la página actual
  const paginatedResults = results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        maxHeight: '90vh',
        bgcolor: 'background.paper',
        border: '1px solid #ccc',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        overflowY: 'auto',
        fontFamily: 'Arial, sans-serif',
      }}>
        <Button
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            minWidth: 'auto',
            padding: 0,
            color: '#555'
          }}
        >
          <CloseIcon />
        </Button>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={exportToExcel}
            startIcon={<DescriptionOutlinedIcon />}
          >
            Descargar
          </Button>
          <Button
            sx={{ marginLeft: 2 }}
            variant="contained"
            color="primary"
            onClick={exportToPDF}
            startIcon={<PrintIcon />}
          >
            Imprimir
          </Button>
        </Box>
        <Box
          sx={{
            mb: 3,
            textAlign: 'center',
            border: '1px solid',
            p: 2,
            borderRadius: 2,
            borderColor: '#4a90e2'
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: 'bold', color: '#333', mb: 1 }}
          >
            {header.constructora_info}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: '#555', fontStyle: 'italic', mb: 0.5 }}
          >
            Actualizado a la fecha: {header.updated_at_date}
          </Typography>
          <Typography variant="body2" sx={{ color: '#555' }}>
            {header.date_filter_text}
          </Typography>
        </Box>

        <Typography
          variant="body2"
          sx={{ textAlign: 'left', fontSize: '0.875rem', color: '#555', mt: 2 }}
        >
          {`Cantidad de cupones encontrados: ${results.length}`}
        </Typography>

        {/* Tabla de Resultados */}
        <TableContainer component={Paper} sx={{ border: '1px solid #ddd', borderRadius: 2 }}>
          <Table aria-label="deferred coupons report">
            <TableHead>
              <TableRow>
                <TableCell><strong>N° de Contrato</strong></TableCell>
                <TableCell><strong>Suscriptor</strong></TableCell>
                <TableCell><strong>Cuotas</strong></TableCell>
                <TableCell><strong>Primer vencimiento</strong></TableCell>
                <TableCell><strong>Porcentaje diferido</strong></TableCell>
                <TableCell><strong>Días diferidos</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedResults.map((result, index) => (
                <TableRow key={index}>
                  <TableCell>{result.numero_orden}</TableCell>
                  <TableCell>{result.subscriber_name}</TableCell>
                  <TableCell>
                    {result.fee_number2 && result.fee_number2 !== 0
                      ? `${result.fee_number1} - ${result.fee_number2}`
                      : result.fee_number1 !== 0
                        ? `${result.fee_number1}`
                        : "Adecuación"}
                  </TableCell>
                  <TableCell>{result.first_due_date}</TableCell>
                  <TableCell>{result.porcentage_diferido} %</TableCell>
                  <TableCell>{result.dias_diferido}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Paginación */}
        <TablePagination
          component="div"
          count={results.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Cupones por página"
          rowsPerPageOptions={[100, 200, 500]}
        />
      </Box>
    </Modal>
  );
};

export default DeferredCouponsModal;