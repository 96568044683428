import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

// Obtener agrupaciones de pagos
export const getGroupedInvoices = createAsyncThunk("payments/groupedInvoices", async ({ start_date, end_date, legal_entity }) => {
  const response = await apiClient.get("/payments/grouped_invoices", {
    params: { start_date, end_date, legal_entity },
  });
  return response.data;
});

// Obtener detalles de cupones
export const getCouponsDetails = createAsyncThunk("payments/couponsDetails", async (coupons_uuids) => {
  const response = await apiClient.post("/payments/coupons_details", { coupons_uuids });
  return response.data;
});

// Descargar factura (invoice)
export const printInvoice = createAsyncThunk("invoice/printInvoice", async (uuid_invoice) => {
  const response = await apiClient.get(`/invoice/download`, {
    params: { uuid_invoice }
  });

  const { pdf_base64, download_name } = response.data;

  // Convertir base64 a Blob
  const byteCharacters = atob(pdf_base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'application/pdf' });

  // Crear un enlace de descarga
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", download_name); // Usar el nombre proporcionado por el backend
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);

  return response.data;
});