import axios from "axios";
import { store } from "../app/store"; // Importa tu store Redux

// const apiUrl = process.env.REACT_APP_API_URL;
const apiUrl = '/api/'; //! reemplazar para deployar

const apiClient = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
});

// Solicita el interceptor para adjuntar el token de autenticación
apiClient.interceptors.request.use(
  (config) => {
    // Obtiene el token del estado Redux
    const token = store.getState().auth.token;

    // Si hay un token, lo adjunta al encabezado de autorización
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiClient;
