import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getCouponsStatistics = createAsyncThunk(
  "couponsStatistics",
  async (filters) => {
    // Filtrar los filtros para eliminar aquellos que no están seteados
    const filteredFilters = Object.fromEntries(
      Object.entries(filters).filter(([_, value]) => value !== "" && value !== null)
    );

    const response = await apiClient.post("statistics/coupons", filteredFilters);
    return response.data;
  }
);