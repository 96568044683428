import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Box,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
} from "@mui/material";

import {
  FormBoxContainer,
  FormDialogSubtitle,
  FormDialogTitle,
  SeparatorHrBox,
  SelectFormControl,
} from "../../assets/styles/tables";
import Actions from "./Actions";

const GroupDialog = (props) => {
  const {
    openGroup,
    handleCloseGroup,
    editModeGroup,
    selectedGroup,
    handleInputChangeGroup,
    handleSelectChangeGroup,
    handleSaveGroup,
  } = props;

  const [nameError, setNameError] = useState(false);
  const [numberFromError, setNumberFromError] = useState(false);
  const [numberToError, setNumberToError] = useState(false);
  // const [subgroupError, setSubgroupError] = useState(false);
  const [saveAttempted, setSaveAttempted] = useState(false);

  useEffect(() => {
    if (openGroup) {
      setNameError(false);
      setNumberFromError(false);
      setNumberToError(false);
      // setSubgroupError(false);
      setSaveAttempted(false);
    }
  }, [openGroup]);

  const handleSave = () => {
    setSaveAttempted(true);

    if (!selectedGroup.name) {
      setNameError(true);
      return;
    }

    if (!selectedGroup.number_from) {
      setNumberFromError(true);
      return;
    }

    if (!selectedGroup.number_to) {
      setNumberToError(true);
      return;
    }

    // if (selectedGroup.subgroup_of === undefined) {
    //   setSubgroupError(true);
    //   return;
    // }

    handleSaveGroup();
  };

  const actionProps = {
    handleClose: handleCloseGroup,
    handleSave: handleSave,
  };

  return (
    <Dialog
      open={openGroup}
      onClose={handleCloseGroup}
      PaperProps={{
        style: { minWidth: "600px", maxWidth: "600px" },
      }}
    >
      <FormDialogTitle>
        {editModeGroup ? "Editar Grupo" : "Agregar Grupo"}
      </FormDialogTitle>
      <SeparatorHrBox>
        <hr />
      </SeparatorHrBox>
      <DialogContent sx={{ display: "block" }}>
        <Box>
          <Box>
            <FormDialogSubtitle>
              {" "}
              {selectedGroup.name === undefined
                ? ""
                : `Datos del grupo "${selectedGroup.name}"`}
            </FormDialogSubtitle>
          </Box>
          <Box>
            <FormBoxContainer sx={{ display: "block" }}>
              <TextField
                margin="dense"
                name="name"
                label="Nombre"
                type="text"
                fullWidth
                value={selectedGroup.name}
                onChange={handleInputChangeGroup}
                error={nameError || (saveAttempted && !selectedGroup.name)}
              />
              {nameError || (saveAttempted && !selectedGroup.name) ? (
                <FormHelperText>Debes ingresar un nombre.</FormHelperText>
              ) : null}
              <TextField
                margin="dense"
                name="number_from"
                label="N°Orden Desde"
                type="number"
                fullWidth
                value={selectedGroup.number_from}
                onChange={handleInputChangeGroup}
                error={
                  numberFromError ||
                  (saveAttempted && !selectedGroup.number_from)
                }
              />
              {numberFromError ||
              (saveAttempted && !selectedGroup.number_from) ? (
                <FormHelperText>
                  Debes ingresar un N° de orden desde.
                </FormHelperText>
              ) : null}
              <TextField
                margin="dense"
                name="number_to"
                label="N°Orden Hasta"
                type="number"
                fullWidth
                value={selectedGroup.number_to}
                onChange={handleInputChangeGroup}
                error={
                  numberToError || (saveAttempted && !selectedGroup.number_to)
                }
              />
              {numberToError || (saveAttempted && !selectedGroup.number_to) ? (
                <FormHelperText>
                  Debes ingresar un N° de orden hasta.
                </FormHelperText>
              ) : null}
              <SelectFormControl>
                <InputLabel margin="dense" id="subgroup_of-label">
                  Es sub-grupo de
                </InputLabel>
                <Select
                  labelId="subgroup_of-label"
                  name="subgroup_of"
                  value={selectedGroup.subgroup_of}
                  onChange={handleSelectChangeGroup}
                  // error={
                  //   subgroupError ||
                  //   (saveAttempted && selectedGroup.subgroup_of === undefined)
                  // }
                >
                  <MenuItem value="0">Grupo 1</MenuItem>
                  <MenuItem value="1">Grupo 2</MenuItem>
                </Select>
                {/* {subgroupError ||
                (saveAttempted && selectedGroup.subgroup_of === undefined) ? (
                  <FormHelperText>
                    Debes seleccionar si es sub-grupo de otro grupo.
                  </FormHelperText>
                ) : null} */}
              </SelectFormControl>
            </FormBoxContainer>
          </Box>
        </Box>
      </DialogContent>
      <Actions {...actionProps} />
    </Dialog>
  );
};

export default GroupDialog;
