import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getContractFilter = createAsyncThunk(
  "registerPayment/coupon_filter",
  async (filters) => {
    const response = await apiClient.post(
      "register_coupon/coupon_filter",
      filters // Esto enviará los filtros en el cuerpo de la solicitud
    );

    return response.data;
  }
);

export const addRegisterPayment = createAsyncThunk(
  "registerPayment/add",
  async (register_payment) => {
    const response = await apiClient.put(
      "register_coupon/register_payment",
      register_payment
    );
    return response;
  }
);

export const addInvoices = createAsyncThunk(
  "invoices/add",
  async (register_payment) => {
    const response = await apiClient.post("invoices", register_payment);
    return response.data;
  }
);
