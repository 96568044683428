import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getContractsCount = createAsyncThunk(
  "contracts_count",
  async (archived) => {
    const response = await apiClient.get(
      `contracts_count/${archived.toString()}`
    );
    // Retornar un objeto que incluya ambos, 'data' y 'archived'
    return { data: response.data, archived };
  }
);

export const getContracts = createAsyncThunk(
  "contracts",
  async ({ archived, ...filters } = {}, { getState }) => {
    // Extrae 'archived' de los filtros
    // Asegúrate de que 'archived' sea un string 'true' o 'false'
    const archivedString = String(archived);

    // Obten los filtros del estado de Redux o usa un objeto vacío si no hay filtros preestablecidos
    const currentFilters = getState().contracts.filters || {};

    // Combina los filtros actuales con los nuevos filtros proporcionados
    const mergedFilters = { ...currentFilters, ...filters };

    // Limpia cualquier filtro vacío antes de enviar la solicitud
    ["uuid_plan", "uuid_group", "has_subscribers"].forEach((key) => {
      if (mergedFilters[key] === "") {
        delete mergedFilters[key];
      }
    });

    // Construye la URL con el parámetro 'archived' como parte del path
    const url = `contracts_filter/${archivedString}`;

    // Realiza la solicitud al backend con los filtros combinados
    const response = await apiClient.get(url, {
      params: mergedFilters,
    });

    return { data: response.data, archived };
  }
);

export const getContractById = createAsyncThunk("contract/id", async (id) => {
  const response = await apiClient.get(`contract/${id}`);
  return response.data;
});

export const deleteContractById = createAsyncThunk(
  "contract/delete",
  async (id) => {
    const response = await apiClient.delete(`contract/${id}`);
    return response.data;
  }
);

export const addContract = createAsyncThunk(
  "contracts/add",
  async (contract) => {
    const contractCopy = { ...contract };
    delete contractCopy.deleted_at;
    delete contractCopy.updated_at;
    delete contractCopy.created_at;
    delete contractCopy.is_active;

    const response = await apiClient.post("contracts", contractCopy);
    return response.data;
  }
);

export const updateContract = createAsyncThunk(
  "contract/update",
  async (contract) => {
    const contractCopy = { ...contract };
    delete contractCopy.deleted_at;
    delete contractCopy.updated_at;
    delete contractCopy.created_at;
    delete contractCopy.is_active;
    delete contractCopy.subscribers;
    delete contractCopy.additionals;

    const response = await apiClient.put(
      `contract/${contractCopy.uuid}`,
      contractCopy
    );
    return response.data;
  }
);

export const updateContractSubscriber = createAsyncThunk(
  "contract_subscriber/update",
  async (contractSubscriber) => {
    const subscriberCopy = { ...contractSubscriber };
    // delete subscriberCopy.deleted_at;
    // delete subscriberCopy.updated_at;
    // delete subscriberCopy.created_at;
    // delete subscriberCopy.is_active;
    const response = await apiClient.put("contract_subscriber", subscriberCopy);
    return response.data;
  }
);

export const addContractSubscriber = createAsyncThunk(
  "contract_subscriber/add",
  async (contractSubscriber) => {
    const subscriberCopy = { ...contractSubscriber };
    // delete subscriberCopy.deleted_at;
    // delete subscriberCopy.updated_at;
    // delete subscriberCopy.created_at;
    // delete subscriberCopy.is_active;
    const response = await apiClient.post(
      "contract_subscriber",
      subscriberCopy
    );
    return response.data;
  }
);

export const deleteContractSubscriber = createAsyncThunk(
  "contract_subscriber/delete",
  async (contractSubscriber) => {
    const { uuid_subscriber, uuid_contract } = contractSubscriber;
    const requestData = { uuid_subscriber, uuid_contract };

    const response = await apiClient.delete("contract_subscriber", {
      data: requestData,
    });
    return response.data;
  }
);

export const updateContractAdditional = createAsyncThunk(
  "additional/update",
  async (contractAdditional) => {
    const additionalCopy = { ...contractAdditional };
    // delete additionalCopy.deleted_at;
    // delete additionalCopy.updated_at;
    // delete additionalCopy.created_at;
    // delete additionalCopy.is_active;

    const response = await apiClient.put("contract_additional", additionalCopy);
    return response.data;
  }
);

export const addContractAdditional = createAsyncThunk(
  "additional/add",
  async (contractAdditional) => {
    const additionalCopy = { ...contractAdditional };
    // delete additionalCopy.deleted_at;
    // delete additionalCopy.updated_at;
    // delete additionalCopy.created_at;
    // delete additionalCopy.is_active;

    const response = await apiClient.post(
      "contract_additional",
      additionalCopy
    );
    return response.data;
  }
);

export const deleteContractAdditional = createAsyncThunk(
  "additional/delete",
  async (contractSubscriber) => {
    const { uuid } = contractSubscriber;
    const requestData = { uuid };

    const response = await apiClient.delete("contract_additional", {
      data: requestData,
    });
    return response.data;
  }
);

export const releaseContract = createAsyncThunk(
  "release_contract/add",
  async (contract) => {
    const { release_reason_obs, uuid_release_reason } = contract;
    const requestData = { release_reason_obs, uuid_release_reason };

    const response = await apiClient.post(`release_contract/${contract.uuid}`, {
      data: requestData,
    });
    return response.data;
  }
);

export const archiveContract = createAsyncThunk(
  "archive_contract",
  async ({ uuid, action }) => {
    const response = await apiClient.post(`archive_contract/${uuid}/${action}`);
    return response.data;
  }
);