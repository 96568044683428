import {
  AddButton,
  GeneralTableBox,
  GeneralTableContainer,
  HeadTableCell,
  RowTableCell,
  SelectFormControl,
} from "../assets/styles/tables";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import PrintIcon from "@mui/icons-material/Print"; // Ícono de impresión
import {
  Box,
  Button,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton as MuiIconButton,
  TableContainer,
  Paper,
} from "@mui/material";
import Close from "@mui/icons-material/Close"; // Ícono de cruz para cerrar el modal
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { printInvoice, getGroupedInvoices, getCouponsDetails } from "../api/endpoints/payments"; // Acciones para obtener agrupaciones y confirmar cupones

const PaymentsHeader = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  entidadSeleccionada,
  setEntidadSeleccionada,
  handleSearch,
}) => {
  const navigate = useNavigate();
  const entidadesRec = useSelector((state) => state.legalEntity.recLegalEntities); // Obtener entidades recaudadoras

  const handleNavigate = () => {
    navigate("/cupon/registrar/registrar-cobro");
  };

  return (
    <Box
      sx={{
        mt: 3,
        justifyContent: "center",
        backgroundColor: "#f2f2f2",
        borderRadius: "20px",
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
        padding: "15px",
        boxShadow: "none",
        borderBottom: "1px solid #ccc",
      }}
    >
      <Box
        sx={{
          fontSize: "25px",
          fontWeight: "bold",
        }}
      >
        Cupon | Registrar | Ver cobros
      </Box>
      <Box sx={{ ml: 0, mt: 2 }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#5e894f",
            borderRadius: "15px",
            height: "55px",
            width: "220px",
            fontSize: "100%",
            boxShadow: "5px 5px 20px rgba(1,2,10,0.3)",
          }}
          onClick={handleNavigate}
        >
          Registrar cobro
          <AddIcon />
        </Button>
        <TextField
          label="Fecha Desde"
          type="date"
          sx={{ ml: 2, mt: 0, width: "15%" }}
          InputLabelProps={{
            shrink: true,
          }}
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <TextField
          label="Fecha Hasta"
          type="date"
          sx={{ ml: 2, mt: 0, width: "15%" }}
          InputLabelProps={{
            shrink: true,
          }}
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        <SelectFormControl sx={{ ml: 2, mt: 0, width: "20%" }}>
          <InputLabel
            id="entidad-rec-label"
            shrink
            style={{ backgroundColor: "whitesmoke" }}
          >
            Entidad Rec.
          </InputLabel>
          <Select
            labelId="entidad-rec-label"
            name="entidadRec"
            value={entidadSeleccionada}
            onChange={(e) => setEntidadSeleccionada(e.target.value)}
            notched
          >
            <MenuItem value="todas">Todas</MenuItem>
            {entidadesRec.map((entidad) => (
              <MenuItem key={entidad.uuid} value={entidad.uuid}>
                {entidad.company}
              </MenuItem>
            ))}
          </Select>
        </SelectFormControl>

        <Button
          variant="contained"
          sx={{
            backgroundColor: "#5e894f",
            borderRadius: "15px",
            height: "55px",
            width: "150px",
            fontSize: "100%",
            boxShadow: "5px 5px 20px rgba(1,2,10,0.3)",
            marginLeft: "10px",
          }}
          onClick={handleSearch}
        >
          Buscar
        </Button>
      </Box>
    </Box>
  );
};

const PaymentsForm = () => {
  const dispatch = useDispatch();
  const [entidadSeleccionada, setEntidadSeleccionada] = useState("todas");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [exportData, setExportData] = useState([]); // Inicializamos el estado local para almacenar los resultados

  // Estado para manejar el popup y los cupones seleccionados
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCoupons, setSelectedCoupons] = useState([]);
  const [dialogPaymentDate, setDialogPaymentDate] = useState("");
  const [dialogInvoiceDate, setDialogInvoiceDate] = useState("");
  const [dialogCollectorEntity, setDialogCollectorEntity] = useState("");

  // Función para hacer la búsqueda cuando se hace clic en "Buscar"
  const handleSearch = async () => {
    if (startDate && endDate) {
      try {
        const response = await dispatch(
          getGroupedInvoices({ start_date: startDate, end_date: endDate, legal_entity: entidadSeleccionada })
        ).unwrap();
        setExportData(response); // Guardamos los datos en el estado local
      } catch (error) {
        console.error("Error fetching grouped invoices", error);
      }
    } else {
      alert("Debes seleccionar las fechas");
    }
  };

  // Función para confirmar facturación (enviar los cupones UUIDs al backend)
  const handleConfirm = async (couponsUUIDs, paymentDate, invoiceDate, collectorEntity) => {
    try {
      const response = await dispatch(getCouponsDetails(couponsUUIDs)).unwrap();
      setSelectedCoupons(response); // Guardamos los cupones en el estado
      setDialogPaymentDate(paymentDate);
      setDialogInvoiceDate(invoiceDate);
      setDialogCollectorEntity(collectorEntity);
      setOpenDialog(true); // Abrimos el popup
    } catch (error) {
      console.error("Error al confirmar cupones", error);
    }
  };

  // Función para cerrar el popup
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mr: 1,
        ml: 1,
        mb: 1
      }}
    >
      <PaymentsHeader
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        entidadSeleccionada={entidadSeleccionada}
        setEntidadSeleccionada={setEntidadSeleccionada}
        handleSearch={handleSearch}
      />
      <Box
        sx={{
          mt: 0,
          ml: 0,
          mr: 0,
          width: "100%",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
          boxShadow: "none",
        }}
      >
        <TableContainer
          component={Paper} // Puedes usar Paper para añadir un fondo
          sx={{
            maxHeight: "45em",
            overflow: "auto",
            width: "100%", // Asegúrate de que ocupe todo el ancho
            backgroundColor: "#f2f2f2",
          }}
        >
          <Table aria-label="payments table">
            <TableHead>
              <TableRow>
                <HeadTableCell sx={{ fontSize: '16px', textAlign: "center", width: "15%" }}>
                  <span>
                    Fecha
                    <br />
                    Pago / Factura
                  </span>
                </HeadTableCell>
                <HeadTableCell sx={{ fontSize: '16px', textAlign: "center", width: "3%" }}>
                  Cupones
                </HeadTableCell>
                <HeadTableCell sx={{ fontSize: '16px', textAlign: "center", width: "15%" }}>
                  Entidad Rec.
                </HeadTableCell>
                <HeadTableCell sx={{ fontSize: '16px', textAlign: "center", width: "15%" }}>
                  <span>
                    Facturas
                    <br />
                    desde-hasta
                  </span>
                </HeadTableCell>
                <HeadTableCell sx={{ fontSize: '16px', textAlign: "center", width: "15%" }}>
                  Total Cupon
                </HeadTableCell>
                <HeadTableCell sx={{ fontSize: '16px', textAlign: "center", width: "15%" }}>
                  Total Facturado
                </HeadTableCell>
                <HeadTableCell sx={{ fontSize: '16px', textAlign: "center", width: "3%" }}>
                  Ver
                </HeadTableCell>
                <HeadTableCell sx={{ fontSize: '16px', textAlign: "center", width: "3%" }}>
                  Facturado
                </HeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {exportData.map((data, key) => (
                <TableRow key={key} sx={{ fontSize: "12px" }}>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    Pago: {new Date(new Date(data.payment_date).setDate(new Date(data.payment_date).getDate() + 1)).toLocaleDateString("es-AR", { year: "numeric", month: "2-digit", day: "2-digit" })} <br />
                    Fact.: {new Date(new Date(data.invoice_date).setDate(new Date(data.invoice_date).getDate() + 1)).toLocaleDateString("es-AR", { year: "numeric", month: "2-digit", day: "2-digit" })}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {data.coupons_count}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {data.collector_entity}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {data.invoice_range.split(" to ")[0]} <br />
                    {data.invoice_range.split(" to ")[1]}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {parseFloat(data.total_coupon_amount).toLocaleString("es-AR", {
                      style: "currency",
                      currency: "ARS",
                    })}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {parseFloat(data.total_invoiced_amount).toLocaleString("es-AR", {
                      style: "currency",
                      currency: "ARS",
                    })}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() =>
                        handleConfirm(
                          data.coupons_uuids,
                          data.payment_date,
                          data.invoice_date,
                          data.collector_entity
                        )
                      }
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {data.invoiced ? (
                      <IconButton size="small" color="primary">
                        <CheckIcon />
                      </IconButton>
                    ) : (
                      <IconButton size="small" color="primary">
                        <CloseIcon />
                      </IconButton>
                    )}
                  </RowTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Dialog para mostrar los detalles de los cupones */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="xl" fullWidth>
        <DialogTitle>
          Facturas Realizadas el{" "}
          {new Date(dialogInvoiceDate).toLocaleString()},
          Entidad Rec: {dialogCollectorEntity},
          Total de comprobantes: {selectedCoupons.length}
          <MuiIconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </MuiIconButton>
        </DialogTitle>
        <DialogContent>
          <Table aria-label="coupons details table">
            <TableHead>
              <TableRow>
                <HeadTableCell sx={{ fontSize: '16px' }}>Fecha Factura</HeadTableCell>
                <HeadTableCell sx={{ fontSize: '16px' }}>NºFactura Int.</HeadTableCell>
                <HeadTableCell sx={{ fontSize: '16px' }}>Contrato</HeadTableCell>
                <HeadTableCell sx={{ fontSize: '16px' }}>Suscriptor</HeadTableCell>
                <HeadTableCell sx={{ fontSize: '16px' }}>Entidad Rec.</HeadTableCell>
                <HeadTableCell sx={{ fontSize: '16px' }}>Total Cupon</HeadTableCell>
                <HeadTableCell sx={{ fontSize: '16px' }}>Total Factura</HeadTableCell>
                <HeadTableCell sx={{ fontSize: '16px', textAlign: "center" }}>Facturado</HeadTableCell>
                <HeadTableCell sx={{ fontSize: '16px', textAlign: "center" }}>Imprimir</HeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedCoupons.map((coupon, index) => (
                <TableRow key={index} sx={{ fontSize: "12px" }}>
                  <RowTableCell>
                    Pago: {new Date(new Date(dialogPaymentDate).setDate(new Date(dialogPaymentDate).getDate() + 1)).toLocaleDateString("es-AR", { year: "numeric", month: "2-digit", day: "2-digit" })} <br />
                    Fact.: {new Date(new Date(dialogInvoiceDate).setDate(new Date(dialogInvoiceDate).getDate() + 1)).toLocaleDateString("es-AR", { year: "numeric", month: "2-digit", day: "2-digit" })}
                  </RowTableCell>
                  <RowTableCell>{coupon.invoice_number}</RowTableCell>
                  <RowTableCell>
                    <span>
                      Contrato n° {coupon.contract_number}
                      <br />
                      {coupon.fee}
                    </span>
                  </RowTableCell>

                  <RowTableCell>
                    {coupon.subscribers.split(",").map((subscriber, index) => (
                      <span key={index}>
                        {subscriber}
                        <br />
                      </span>
                    ))}
                  </RowTableCell>
                  <RowTableCell>{dialogCollectorEntity}</RowTableCell>
                  <RowTableCell>
                    {parseFloat(coupon.total_coupon).toLocaleString("es-AR", {
                      style: "currency",
                      currency: "ARS",
                    })}
                  </RowTableCell>
                  <RowTableCell>
                    {parseFloat(coupon.total_invoiced).toLocaleString("es-AR", {
                      style: "currency",
                      currency: "ARS",
                    })}
                  </RowTableCell>
                  <RowTableCell style={{ textAlign: "center" }}>
                    <CheckIcon color="primary" />
                  </RowTableCell>
                  <RowTableCell style={{ textAlign: "center" }}>
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => dispatch(printInvoice(coupon.invoice_uuid))} // Aquí llamas al printInvoice con el UUID de la factura
                    >
                      <PrintIcon />
                    </IconButton>
                  </RowTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Box >
  );
};

export default PaymentsForm;
