import React from "react";
import StatisticsForm from "../common/StatisticsForm";
import { Box } from "@mui/material";

const Statistics = () => {
  return (
    <Box mt={12}>
      <StatisticsForm/>
    </Box>
  );
};

export default Statistics;
