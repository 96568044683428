import { createSlice } from "@reduxjs/toolkit";
import {
  getContracts,
  getContractsCount,
  releaseContract,
  updateContract,
} from "../../api/endpoints/contracts";

const initialContractToEdit = {
  additional_construction_subtotal: 0,
  additional_construction_total: 0,
  additionals: [],
  adjudication_date: "",
  adjudication_home_address: "",
  adjudication_obs: "",
  by_email: false,
  cancel_date: "",
  cancel_obs: "",
  construction_additional_obs: "",
  construction_site_location: "",
  contract_number: "",
  created_at: "",
  current_holder_date: "",
  current_holder_obs: "",
  current_possessor_date: "",
  current_possessor_obs: "",
  debt_status: "0",
  deleted_at: "",
  first_fee: "0",
  initial_subscription_date: "",
  is_active: true,
  is_premium: false,
  last_generated_advance_fee: "0",
  last_generated_fee: "0",
  life_insurance: 0,
  not_generate: false,
  pre_adjudication_date: "",
  pre_adjudication_obs: "",
  private_land: false,
  property_deed_date: "",
  property_deed_obs: "",
  release_reason_obs: "",
  removal_construction_subtotal: 0,
  request_number: "0",
  subscribers: [],
  subscription_obs: "",
  updated_at: "",
  uuid: "",
  uuid_construction_type: "None",
  uuid_group: "",
  uuid_legal_entity: "None",
  uuid_payment_method: "None",
  uuid_plan: "",
  uuid_pre_adjudication_reason: "",
  uuid_release_reason: "None",
  uuid_user: "",
};

export const ContractSlice = createSlice({
  name: "Contracts",
  initialState: {
    contracts: [],
    archivedContracts: [],
    contractCount: {
      no_subscribers: {},
      with_subscribers: {},
      no_group: {},
    },
    archivedContractCount: {
      no_subscribers: {},
      with_subscribers: {},
      no_group: {},
    },
    contractsToEditByGroup: [],
    contractToEdit: initialContractToEdit,
    contractSubscribers: {},
    contractAdditionals: {},
    loadingContracts: false,
    isArchiveDialog: false
  },
  reducers: {
    setContracts: (state, action) => {
      state.contracts = action.payload;
    },
    setContractCount: (state, action) => {
      state.contractCount = action.payload;
    },
    setArchivedContracts: (state, action) => {
      state.archivedContracts = action.payload;
    },
    setArchivedContractCount: (state, action) => {
      state.archivedContractCount = action.payload;
    },
    setContractsToEditByGroup: (state, action) => {
      state.contractsToEditByGroup = action.payload.data;
    },
    setContractToEdit: (state, action) => {
      state.contractToEdit = action.payload;
    },
    removeContract: (state, action) => {
      const index = state.contracts.findIndex(
        (contract) => contract.uuid === action.payload
      );
      if (index !== -1) {
        state.contracts.splice(index, 1);
      }
    },
    updateContractGeneral: (state, action) => {
      const { uuid, updatedContract } = action.payload;
      const index = state.contracts.findIndex(
        (contract) => contract.uuid === uuid
      );
      if (index !== -1) {
        state.contracts[index] = updatedContract;
      }
    },
    setContractSubscribers: (state, action) => {
      state.contractSubscribers = action.payload;
    },
    addSubscriberToContract: (state, action) => {
      const subscriber = action.payload;
      state.contractSubscribers[subscriber.uuid_subscriber] = subscriber;
    },
    setContractAdditionals: (state, action) => {
      state.contractAdditionals = action.payload;
    },
    setLoadingContractsTrue: (state) => {
      state.loadingContracts = true;
    },
    setLoadingContractsFalse: (state) => {
      state.loadingContracts = false;
    },
    setArchiveDialogTrue: (state) => {
      state.isArchiveDialog = true;
    },
    setArchiveDialogFalse: (state) => {
      state.isArchiveDialog = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getContracts.fulfilled, (state, action) => {
      const { data, archived } = action.payload;
      if (archived) {
        state.archivedContracts = data;
      } else {
        state.contracts = data;
      }
    });
    builder.addCase(getContractsCount.fulfilled, (state, action) => {
      const { data, archived } = action.payload;
      if (archived) {
        state.archivedContractCount = data;
      } else {
        state.contractCount = data;
      }
    });
    builder.addCase(updateContract.fulfilled, (state, action) => {
      const updatedContract = action.payload;
      const index = state.contractsToEditByGroup.findIndex(
        (contract) => contract.uuid === updatedContract.uuid
      );
      if (index !== -1) {
        state.contractsToEditByGroup[index] = updatedContract;
      }
    });
    // Manejar la liberación del contrato aquí:
    builder.addCase(releaseContract.fulfilled, (state, action) => {
      const releasedContract = action.payload;

      // Marca el contrato original como inactivo
      const originalIndex = state.contracts.findIndex(
        (contract) => contract.uuid === releasedContract.uuid
      );
      if (originalIndex !== -1) {
        state.contracts[originalIndex].is_active = false;
      }

      // Añade el nuevo contrato a la lista
      state.contracts.push({
        ...initialContractToEdit,
        ...releasedContract,
        is_active: true,
      });
    });
  },
});

export const {
  setContracts,
  setArchivedContracts,
  setArchivedContractCount,
  setContractsToEditByGroup,
  setContractToEdit,
  removeContract,
  updateContractGeneral,
  setContractSubscribers,
  addSubscriberToContract,
  setContractAdditionals,
  setLoadingContractsTrue,
  setLoadingContractsFalse,
  setArchiveDialogTrue,
  setArchiveDialogFalse,
} = ContractSlice.actions;

export default ContractSlice.reducer;
