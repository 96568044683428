import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDebtCoupons,
  updateDebtCoupons,
} from "../api/endpoints/updatedDebt";
import {
  FormDialogTitle,
  GeneralTableBox,
  GeneralTableContainer,
  HeadTableCell,
  PaginationBox,
  RowTableCell,
  SelectFormControl
} from "../assets/styles/tables";

const ITEMS_PER_PAGE = 10; // Establece tu valor predeterminado para items por página
const dateNow = new Date(); // Creating a new date object with the current date and time
const year = dateNow.getFullYear(); // Getting current year from the created Date object
const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
const month = // Setting current Month number from current Date object
  monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
    ? `0${monthWithOffset}`
    : monthWithOffset;
const date =
  dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
    ? `0${dateNow.getUTCDate()}`
    : dateNow.getUTCDate();

const materialDateInput = `${year}-${month}-${date}`;

const formatDate = (dateStr) => {
  const [year, month, day] = dateStr.split(" ")[0].split("-");
  return `${day}/${month}/${year}`;
};

const CouponDetailsModal = ({ open, onClose, item }) => {
  const handlePrint = () => {
    const originalSize = document.body.style.zoom;
    document.body.style.zoom = "70%"; // Disminuye el zoom para ajustar el contenido en la impresión
    window.print();
    document.body.style.zoom = originalSize; // Restablece el zoom original después de la impresión
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          background: "white",
          padding: 3,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: 2,
          width: "90%", // Ancho automático para adaptarse al contenido
          overflow: "auto",
          height: "90%"
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Typography variant="h6">CUOTAS ADEUDADAS</Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={handlePrint}>
            <PrintIcon />
          </IconButton>
        </Box>
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="subtitle1">
            ORDEN NÚMERO: {item?.header?.contract_number}
          </Typography>
          <Typography variant="subtitle1">
            SUSCRIPTOR: {item?.header?.subscriber}
          </Typography>
        </Box>
        <GeneralTableContainer
          sx={{
            overflow: "auto",
            maxWidth: "100%",
          }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Numero Cuota</TableCell>
                <TableCell>Fecha 1er Venc.</TableCell>
                <TableCell>Importe 1er</TableCell>
                <TableCell>Fecha 2do Venc.</TableCell>
                <TableCell>Importe 2do </TableCell>
                <TableCell>Fecha 3er Venc.</TableCell>
                <TableCell>Importe 3er</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {item?.coupons?.map((coupon, index) => (
                <TableRow key={index}>
                  <TableCell>Cuota: {coupon.fee_number1}</TableCell>
                  <TableCell>{formatDate(coupon.first_due_date)}</TableCell>
                  <TableCell> $ {coupon.first_due_amount}</TableCell>
                  <TableCell>{formatDate(coupon.second_due_date)}</TableCell>
                  <TableCell> $ {coupon.second_due_amount}</TableCell>
                  <TableCell>{formatDate(coupon.third_due_date)}</TableCell>
                  <TableCell> $ {coupon.third_due_amount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </GeneralTableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 2,
            mr: 2,
          }}
        >
          <Typography variant="subtitle1" sx={{ marginRight: 2 }}>
            Total: ${item?.header?.total}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

const UpdateDebtHeader = ({ isOpen, setIsOpen, filter, setFilter }) => {
  const { plans } = useSelector((state) => state.plans);
  const { groups } = useSelector((state) => state.groups);

  // const [filter, setFilter] = useState({
  //   plan: plans.length > 0 ? plans[0].uuid : "", // Primer elemento de los planes como valor predeterminado
  //   group: "",
  //   order: "",
  // });

  const handleFilter = (event) => {
    setFilter({
      ...filter,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Box
      sx={{
        mt: 3,
        justifyContent: "center",
        backgroundColor: "#f2f2f2",
        borderRadius: "20px",
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
        padding: "15px",
        boxShadow: "none",
        borderBottom: "1px solid #ccc",
      }}
    >
      <Box
        sx={{
          fontSize: "25px",
          fontWeight: "bold",
        }}
      >
        Cupon | Cupones | Actualizar Deuda
      </Box>
      <Box sx={{ ml: 0, mt: 2 }}>
        <SelectFormControl sx={{ ml: 2, mt: 0, width: "15%" }}>
          <InputLabel margin="dense" id="trimestre-label">
            Plan
          </InputLabel>
          <Select
            labelId="plan-label"
            id="plan-select"
            value={filter.plan}
            onChange={handleFilter}
            name="plan"
          >
            {plans.map((plan) => (
              <MenuItem key={plan.uuid} value={plan.uuid}>
                {plan.name}
              </MenuItem>
            ))}
          </Select>
        </SelectFormControl>
        <SelectFormControl sx={{ ml: 2, mt: 0, width: "15%" }}>
          <InputLabel margin="dense" id="group-label">
            Grupo
          </InputLabel>
          <Select
            labelId="group-label"
            id="group-select"
            value={filter.group}
            onChange={handleFilter}
            name="group"
          >
            <MenuItem value="">Todos</MenuItem>
            {groups.map((group) => (
              <MenuItem key={group.uuid} value={group.uuid}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </SelectFormControl>
        <SelectFormControl sx={{ ml: 2, mt: 0, width: "15%" }}>
          <TextField
            label="Orden"
            id="contract_number-input"
            value={filter.contract_number}
            onChange={handleFilter}
            name="contract_number"
          />
        </SelectFormControl>
      </Box>
    </Box>
  );
};

const UpdateDebtForm = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const { plans } = useSelector((state) => state.plans);

  const [filter, setFilter] = useState({
    plan: plans.length > 0 ? plans[0].uuid : "", // Primer elemento de los planes como valor predeterminado
    group: "",
    contract_number: "",
  });
  const [modifiedCoupons, setModifiedCoupons] = useState([]);

  const [secondExpirationDate, setSecondExpirationDate] =
    useState(materialDateInput);

  const handleSecondExpirationDateChange = (e) => {

    if (!secondExpirationDate) {
      console.error("secondExpirationDate no está definido.");
      return;
    }

    // Actualizar la fecha de tercer vencimiento para todos los cupones
    const updatedCoupons = selectedItem.coupons.map((coupon) => {
      const firstDueDate = new Date(coupon.first_due_date);
      const thirdDueDate = new Date(secondExpirationDate);
      const diffTime = Math.max(thirdDueDate - firstDueDate, 0);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      // Calcular el interés con el 0.33% diario
      const interest = coupon.first_due_amount * 0.0033 * diffDays;

      return {
        ...coupon,
        third_due_date: secondExpirationDate,
        interest: interest,
        third_due_amount: coupon.first_due_amount + interest, // Actualizar también el monto del tercer vencimiento
      };
    });

    setSelectedItem((prev) => ({
      ...prev,
      coupons: updatedCoupons,
    }));
    setModifiedCoupons(updatedCoupons);
  };

  // Esta función se encargará de llamar al endpoint con los filtros y paginación correspondientes
  const fetchDataWithFilters = async () => {
    setIsLoading(true);
    try {
      const response = await dispatch(
        getDebtCoupons({
          plan: filter.plan,
          group: filter.group,
          contract_number: filter.contract_number,
          page: page,
          per_page: ITEMS_PER_PAGE,
        })
      );
      setData(response.payload.grouped_coupons);
      setTotalPages(response.payload.pages);
      setTotalCount(response.payload.total);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  // Llamamos a fetchDataWithFilters cuando el componente se monta y cuando cambian los filtros o el número de página
  useEffect(() => {
    fetchDataWithFilters();
  }, [filter, page]);

  const handleOpenDialog = (item) => {
    setSelectedItem(item);
    setSecondExpirationDate(materialDateInput);
    setOpenDialog(true);
    setModifiedCoupons([]);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDetail = (item) => {
    setSelectedItem(item);
    setOpenDetail(true);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };

  // Cambiar el monto del tercer vencimiento
  const handleThirdDueAmountChange = (index, newValue) => {
    const newModifiedCoupons = [...modifiedCoupons];

    // Si no existe, inicializa el objeto de cupones modificados
    if (!newModifiedCoupons[index]) {
      newModifiedCoupons[index] = { ...selectedItem.coupons[index] };
    }

    // Actualiza el monto
    newModifiedCoupons[index].third_due_amount = parseFloat(newValue) || 0;

    // Si no se ha modificado la fecha, asegúrate de que esté presente
    newModifiedCoupons[index].third_due_date = newModifiedCoupons[index].third_due_date || selectedItem.coupons[index].third_due_date;

    setModifiedCoupons(newModifiedCoupons);
  };

  // Cambiar la fecha del tercer vencimiento
  const handleThirdDueDateChange = (index, newDate) => {
    const newModifiedCoupons = [...modifiedCoupons];

    // Si el cupón no existe en modifiedCoupons, creamos una copia del cupón original de selectedItem
    if (!newModifiedCoupons[index]) {
      newModifiedCoupons[index] = { ...selectedItem.coupons[index] };
    }

    // Actualizamos la fecha del tercer vencimiento
    newModifiedCoupons[index].third_due_date = newDate;

    // Asegurarnos de mantener el monto del tercer vencimiento si no ha sido modificado
    newModifiedCoupons[index].third_due_amount = newModifiedCoupons[index].third_due_amount || selectedItem.coupons[index].third_due_amount;

    setModifiedCoupons(newModifiedCoupons);
  };


  const [payChecks, setPayChecks] = useState([]);
  const [regularizeChecks, setRegularizeChecks] = useState([]);

  // Cuando se seleccionan los cupones, inicializa los estados de los checks
  useEffect(() => {
    if (selectedItem?.coupons) {
      // Inicializa los arrays de checks con el valor booleano correspondiente
      setPayChecks(selectedItem.coupons.map(() => false)); // Todos en false al principio
      setRegularizeChecks(selectedItem.coupons.map(() => false)); // Todos en false al principio
    }
  }, [selectedItem]);

  const handlePayCheckChange = (index) => {
    const updatedPayChecks = [...payChecks];
    updatedPayChecks[index] = !updatedPayChecks[index]; // Cambia solo el checkbox de pago de este cupón
    setPayChecks(updatedPayChecks);

    // Si se marca un check de 'Pagar', desactiva el de 'Regularizar' solo para el mismo cupón
    if (updatedPayChecks[index]) {
      const updatedRegularizeChecks = [...regularizeChecks];
      updatedRegularizeChecks[index] = false; // Desactiva solo el checkbox de regularizar para este cupón
      setRegularizeChecks(updatedRegularizeChecks);
    }
  };

  const handleRegularizeCheckChange = (index) => {
    const updatedRegularizeChecks = [...regularizeChecks];
    updatedRegularizeChecks[index] = !updatedRegularizeChecks[index]; // Cambia solo el checkbox de regularizar de este cupón
    setRegularizeChecks(updatedRegularizeChecks);

    // Si se marca un check de 'Regularizar', desactiva el de 'Pagar' solo para el mismo cupón
    if (updatedRegularizeChecks[index]) {
      const updatedPayChecks = [...payChecks];
      updatedPayChecks[index] = false; // Desactiva solo el checkbox de pagar para este cupón
      setPayChecks(updatedPayChecks);
    }
  };

  const getModifiedCoupons = () => {
    const modifiedCoupons = [];
    payChecks.forEach((checked, index) => {
      if (checked || regularizeChecks[index]) {
        const coupon = {
          ...selectedItem.coupons[index],
          action: checked ? "Pagar" : "Regularizar",
          interest: modifiedCoupons[index]?.interest || selectedItem.coupons[index]?.interest,
        };
        modifiedCoupons.push(coupon);
      }
    });
    return modifiedCoupons;
  };

  const handleSave = () => {
    // Prepara los datos para enviar solo de los cupones que han sido seleccionados
    const dataToSend = {
      coupons: selectedItem.coupons
        .map((coupon, index) => {
          // Verificamos si el cupón ha sido marcado para pagar o regularizar
          if (payChecks[index] || regularizeChecks[index]) {
            const modifiedCoupon = modifiedCoupons[index] || {};

            // Determina la acción para cada cupón individual según el checkbox seleccionado
            const actionType = payChecks[index] ? "pago" : regularizeChecks[index] ? "regulariza" : null;

            if (actionType) {
              return {
                uuid: coupon.uuid,
                third_due_amount:
                  actionType === "pago"
                    ? (modifiedCoupon.third_due_amount || coupon.third_due_amount) + (modifiedCoupon.interest || 0)
                    : modifiedCoupon.third_due_amount || coupon.third_due_amount,
                third_due_date: modifiedCoupon.third_due_date || coupon.third_due_date, // Usa la fecha modificada o la original
                interest: modifiedCoupon.interest || 0,
                action: actionType,
              };
            }
          }
          return null; // Si no está marcado, no enviamos nada
        })
        .filter((coupon) => coupon !== null), // Filtramos los cupones nulos (no seleccionados)
    };

    // Verifica si el payload contiene cupones antes de enviarlo
    if (dataToSend.coupons.length > 0) {
      const response = dispatch(updateDebtCoupons(dataToSend));
      setPayChecks(selectedItem.coupons.map(() => false));
      setRegularizeChecks(selectedItem.coupons.map(() => false));
      setOpenDialog(false);
    } else {
      console.error("No hay cupones seleccionados para enviar.");
    }
  };


  // Actualización del componente total de la deuda
  const calculateTotals = () => {
    let totalPay = 0;
    let totalRegularize = 0;

    payChecks.forEach((checked, index) => {
      if (checked) {
        totalPay += (modifiedCoupons[index]?.third_due_amount || 0) + (modifiedCoupons[index]?.interest || 0);
      }
    });

    regularizeChecks.forEach((checked, index) => {
      if (checked) {
        totalRegularize += modifiedCoupons[index]?.interest || 0;
      }
    });

    return { totalPay, totalRegularize };
  };

  // Función para seleccionar/desmarcar todos los checkboxes de Pagar
  const handlePayAll = () => {
    const allChecked = payChecks.every((checked) => checked);
    setPayChecks(selectedItem.coupons.map(() => !allChecked)); // Asegúrate de que todos los valores estén controlados
    setRegularizeChecks(selectedItem.coupons.map(() => false)); // Desmarca todos los de Regularizar
  };

  // Función para seleccionar/desmarcar todos los checkboxes de Regularizar
  const handleRegularizeAll = () => {
    const allChecked = regularizeChecks.every((checked) => checked);
    setRegularizeChecks(selectedItem.coupons.map(() => !allChecked)); // Asegúrate de que todos los valores estén controlados
    setPayChecks(selectedItem.coupons.map(() => false)); // Desmarca todos los de Pagar
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mr: 1,
        ml: 3,
        mb: 1
      }}
    >
      <UpdateDebtHeader filter={filter} setFilter={setFilter} />
      <Box
        sx={{
          mt: 0,
          ml: 0,
          mr: 0,
          width: "100%",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
          boxShadow: "none",
        }}
      >
        <TableContainer
          component={Paper} // Puedes usar Paper para añadir un fondo
          sx={{
            maxHeight: "45em",
            overflow: "auto",
            width: "100%", // Asegúrate de que ocupe todo el ancho
            backgroundColor: "#f2f2f2",
          }}
        >
          <Table aria-label="plan table">
            <TableHead>
              <TableRow>
                <HeadTableCell sx={{ textAlign: "center" }}>
                  Orden
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center" }}>
                  Suscriptores
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center" }}>
                  Cuotas con Deuda
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center" }}>
                  Ajustes con Deuda
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center" }}>
                  Total
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center" }}>
                  Editar
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center" }}>
                  Ver
                </HeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, key) => (
                <TableRow key={key}>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {item.header.contract_number}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {item.header.subscriber}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {item.header.debt_installment}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {item.header.adequacy_installment}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {item.header.total}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    <IconButton onClick={() => handleOpenDialog(item)}>
                      <EditIcon sx={{ color: "#5e894f" }} />
                    </IconButton>
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    <IconButton onClick={() => handleOpenDetail(item)}>
                      <DocumentScannerIcon sx={{ color: "#5e894f" }} />
                    </IconButton>
                  </RowTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <PaginationBox
        sx={{
          backgroundColor: theme.palette.background.formTables,
          mt: 0,
          borderBottomRightRadius: "20px",
          borderBottomLeftRadius: "20px",
        }}
      >
        <Pagination
          sx={{ mr: 10 }}
          count={totalPages} // Usamos totalPages directamente
          shape="rounded"
          page={page}
          onChange={(event, value) => setPage(value)}
        />
        <Box
          sx={{
            ml: 10,
            fontSize: "15px",
          }}
        >
          Registros: {totalCount}
        </Box>
      </PaginationBox>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="lg"
        sx={{
          overflow: "auto",
        }}
      >
        <DialogContent>
          <FormDialogTitle
            sx={{
              mt: 0,
              mb: 1,
            }}
          >
            DEUDAS A LA FECHA
          </FormDialogTitle>
          <Box mb={3} display="flex" justifyContent="space-around">
            <Box>
              <Typography variant="body1">
                Contrato: {selectedItem?.header.contract_number}
              </Typography>
              <Typography variant="body1">
                Plan:{" "}
                {
                  plans.find((plan) => plan.uuid === selectedItem?.header.plan)
                    ?.name
                }
              </Typography>
              <Typography variant="body1">
                Suscriptor/s: {selectedItem?.header.subscriber}
              </Typography>

              <Box display="flex" alignItems="center" sx={{ marginTop: 2 }}>
                <Button variant="outlined" onClick={handlePayAll}>
                  Pagar todos
                </Button>
                <Button variant="outlined" onClick={handleRegularizeAll} sx={{ ml: 2 }}>
                  Regularizar todos
                </Button>
              </Box>
            </Box>

            <Box display="flex" alignItems="center">
              <TextField
                name="secondExpirationDate"
                type="date"
                size="small"
                value={secondExpirationDate}
                onChange={(e) => setSecondExpirationDate(e.target.value)} // Actualizamos el valor temporalmente
                placeholder="dd/mm/aaaa"
              />
              <IconButton onClick={handleSecondExpirationDateChange}> {/* Ejecutar la función al hacer clic en el botón */}
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
          </Box>

          <Divider />

          {/* Títulos para las columnas Pagar y Regularizar */}
          <Box
            display="flex"
            justifyContent="space-around"
            mt={2}
            mb={2}
            style={{ backgroundColor: "#e0e0e0" }}
          >
            <Typography style={{ width: "60%" }} align="center"></Typography>
            <Typography style={{ width: "11%" }} align="center"></Typography>
            <Typography style={{ width: "10%" }} align="center">
              Pagar
            </Typography>
            <Typography style={{ width: "12%" }} align="center"></Typography>
            <Typography style={{ width: "12%" }} align="center"></Typography>
            <Typography style={{ width: "12%" }} align="center"></Typography>
            <Typography style={{ width: "10%" }} align="center">
              Regularizar
            </Typography>
          </Box>

          {selectedItem?.coupons.map((coupon, index) => (
            <Box
              key={index}
              display="flex"
              justifyContent="space-around"
              alignItems="center"
              mt={2}
              mb={2}
            >
              <Typography variant="body1" style={{ width: "7%" }}>
                Cuota: {coupon.fee_number1}
              </Typography>
              <TextField
                label="$ 1er Venc."
                size="small"
                InputLabelProps={{ shrink: true }}
                value={coupon.first_due_amount}
                style={{ width: "12%" }}
                disabled
              />
              <TextField
                label="Fecha 1er Venc."
                type="date"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={coupon.first_due_date.split(" ")[0]}
                style={{ width: "12%" }}
                disabled
              />
              <TextField
                label="$ 3er Venc."
                size="small"
                InputLabelProps={{ shrink: true }}
                value={
                  typeof modifiedCoupons[index]?.third_due_amount === "number"
                    ? modifiedCoupons[index]?.third_due_amount.toFixed(2)
                    : coupon.third_due_amount?.toFixed(2)
                }
                onChange={(e) => handleThirdDueAmountChange(index, e.target.value)}
                style={{ width: "12%" }}
              />
              <Checkbox
                checked={!!payChecks[index]} // Verifica que sea booleano
                onChange={() => handlePayCheckChange(index)}
                size="small"
              />
              <TextField
                label="Fecha 3er Venc."
                type="date"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={modifiedCoupons[index]?.third_due_date || coupon.third_due_date.split(" ")[0]}
                onChange={(e) => handleThirdDueDateChange(index, e.target.value)}
                style={{ width: "12%" }}
              />
              <TextField
                label="$ Interes"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={
                  modifiedCoupons[index]?.interest?.toFixed(2) ||
                  (coupon.third_due_amount - coupon.first_due_amount)?.toFixed(2)
                }
                style={{ width: "12%" }}
              />
              <Checkbox
                checked={!!regularizeChecks[index]} // Verifica que sea booleano
                onChange={() => handleRegularizeCheckChange(index)}
                size="small"
              />
            </Box>
          ))}

          <Divider />

          <Box
            sx={{
              mt: 2,
            }}
            display="flex"
          >
            <Typography style={{ width: "21%" }} align="center"></Typography>
            <Typography variant="h5">TOTALES:</Typography>
            <Typography style={{ width: "12%" }} align="center"></Typography>
            <Box display="flex" flexDirection="column">
              <TextField
                label="$"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={calculateTotals().totalPay.toFixed(2)} // Total de pagar
                style={{ width: "65%"}}
              />
            </Box>
            <Typography style={{ width: "18%" }} align="center"></Typography>
            <Box display="flex" flexDirection="column">
              <TextField
                label="$"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={calculateTotals().totalRegularize.toFixed(2)} // Total de regularizar
                style={{ width: "65%"}}
              />
            </Box>

          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSave} color="primary">
            Modificar
          </Button>
        </DialogActions>
      </Dialog>
      <CouponDetailsModal
        open={openDetail}
        onClose={handleCloseDetail}
        item={selectedItem ? selectedItem : []}
      />
    </Box>
  );
};
export default UpdateDebtForm;
