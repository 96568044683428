import React, { useState } from 'react';
import { Modal, Box, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper, TableHead, Button, TablePagination } from '@mui/material';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';

const SalesReportModal = ({ open, handleClose, header, results }) => {
  // Estado para la página actual y cantidad de filas por página
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(200); // Mostrar 200 filas por página

  // Cambiar de página
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Cambiar la cantidad de filas por página
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Volver a la primera página cuando cambie la cantidad de filas
  };

  // Formato de moneda en ARS
  const formatCurrency = (value) => {
    return `$ ${value.toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  };

  // Exportar a Excel
  const exportToExcel = () => {
    const data = results.map(result => ({
      "Exportación": result.export_date,
      "Número de Factura": result.invoice_number,
      "CAE": result.cae,
      "Contrato": result.contract_number,
      "Suscriptor": result.subscriber_name,
      "Cuotas": result.fees,
      "Exento": formatCurrency(result.to_exempt),
      "Neto": formatCurrency(result.to_net),
      "IVA": formatCurrency(result.to_iva),
      "Total": formatCurrency(result.to_total)
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "ReporteVentas");

    XLSX.writeFile(workbook, "ReporteVentas.xlsx");
  };

  // Exportar a PDF
  const exportToPDF = () => {
    const doc = new jsPDF("landscape"); // Configura la orientación a 'landscape' (horizontal)
    let yOffset = 20; // Espaciado inicial desde el borde superior

    // Título centrado
    doc.setFontSize(12);
    doc.text("Reporte de Ventas", 148, yOffset, { align: 'center' }); // Ajustar el centrado para una hoja horizontal
    yOffset += 10;

    // Encabezados de la tabla
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text("Fecha", 10, yOffset);
    doc.text("N° Factura", 32, yOffset);
    doc.text("CAE", 68, yOffset);
    doc.text("Órden", 100, yOffset);
    doc.text("Suscriptor", 115, yOffset);
    doc.text("Cuotas", 170, yOffset);
    doc.text("Exento", 192, yOffset);
    doc.text("Neto", 210, yOffset);
    doc.text("IVA", 235, yOffset);
    doc.text("Total", 260, yOffset);
    yOffset += 8; // Espacio después de los encabezados

    doc.setFont("helvetica", "normal");

    // Iterar sobre los resultados para agregar filas a la tabla
    results.forEach((result) => {
      const truncatedName = result.subscriber_name.length > 20 
      ? result.subscriber_name.substring(0, 20) + "..." 
      : result.subscriber_name
      doc.text(result.export_date, 10, yOffset);
      doc.text(result.invoice_number, 32, yOffset);
      doc.text(result.cae, 68, yOffset);
      doc.text(result.contract_number, 100, yOffset);
      doc.text(truncatedName, 115, yOffset);
      doc.text(result.fees, 170, yOffset);
      doc.text(formatCurrency(result.to_exempt), 192, yOffset);
      doc.text(formatCurrency(result.to_net), 210, yOffset);
      doc.text(formatCurrency(result.to_iva), 235, yOffset);
      doc.text(formatCurrency(result.to_total), 260, yOffset);

      yOffset += 8; // Espacio entre filas

      // Crear una nueva página si se llega al límite
      if (yOffset > 190) { // Ajustado para hoja horizontal
        doc.addPage();
        yOffset = 20; // Restablecer espaciado para la nueva página
      }
    });

    doc.save('ReporteVentas.pdf'); // Guardar el PDF
  };

  if (!header || !results || results.length === 0) {
    return null;
  }

  // Calcular los datos para la página actual
  const paginatedResults = results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        maxHeight: '90vh',
        bgcolor: 'background.paper',
        border: '1px solid #ccc',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        overflowY: 'auto',
        fontFamily: 'Arial, sans-serif',
      }}>
        <Button
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            minWidth: 'auto',
            padding: 0,
            color: '#555'
          }}
        >
          <CloseIcon />
        </Button>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={exportToExcel}
            startIcon={<DescriptionOutlinedIcon />}
          >
            Descargar
          </Button>
          <Button
            sx={{ marginLeft: 2 }}
            variant="contained"
            color="primary"
            onClick={exportToPDF}
            startIcon={<PrintIcon />}
          >
            Imprimir
          </Button>
        </Box>
        <Box
          sx={{
            mb: 3,
            textAlign: 'center',
            border: '1px solid',
            p: 2,
            borderRadius: 2,
            borderColor: '#4a90e2'
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: 'bold', color: '#333', mb: 1 }}
          >
            {header.constructora_info}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: '#555', fontStyle: 'italic', mb: 0.5 }}
          >
            Actualizado a la fecha: {header.updated_at_date}
          </Typography>
          <Typography variant="body2" sx={{ color: '#555' }}>
            {header.date_filter_text}
          </Typography>
        </Box>

        <Typography
          variant="body2"
          sx={{ textAlign: 'left', fontSize: '0.875rem', color: '#555', mt: 2 }}
        >
          {`Cantidad de facturas encontradas: ${results.length}`}
        </Typography>

        {/* Tabla de Resultados */}
        <TableContainer component={Paper} sx={{ border: '1px solid #ddd', borderRadius: 2 }}>
          <Table aria-label="sales report">
            <TableHead>
              <TableRow>
                <TableCell><strong>Exportación</strong></TableCell>
                <TableCell><strong>Número de Factura</strong></TableCell>
                <TableCell><strong>CAE</strong></TableCell>
                <TableCell><strong>Contrato</strong></TableCell>
                <TableCell><strong>Suscriptor</strong></TableCell>
                <TableCell><strong>Cuotas</strong></TableCell>
                <TableCell><strong>Exento</strong></TableCell>
                <TableCell><strong>Neto</strong></TableCell>
                <TableCell><strong>IVA</strong></TableCell>
                <TableCell><strong>Total</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedResults.map((result, index) => (
                <TableRow key={index}>
                  <TableCell>{result.export_date}</TableCell>
                  <TableCell>{result.invoice_number}</TableCell>
                  <TableCell>{result.cae}</TableCell>
                  <TableCell>{result.contract_number}</TableCell>
                  <TableCell>{result.subscriber_name}</TableCell>
                  <TableCell>{result.fees}</TableCell>
                  <TableCell>{formatCurrency(result.to_exempt)}</TableCell>
                  <TableCell>{formatCurrency(result.to_net)}</TableCell>
                  <TableCell>{formatCurrency(result.to_iva)}</TableCell>
                  <TableCell>{formatCurrency(result.to_total)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Paginación */}
        <TablePagination
          component="div"
          count={results.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Facturas por página"
          rowsPerPageOptions={[100, 200, 500]}
        />
      </Box>
    </Modal>
  );
};

export default SalesReportModal;
