import React, { useState } from 'react';
import { Modal, Box, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper, TableHead, Button, TablePagination } from '@mui/material';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';

const DebtBalancesModal = ({ open, handleClose, header, results }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(200);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const exportToExcel = () => {
    const data = results.map(result => ({
      "Grupo": result.group,
      "Número de Contrato": result.order_number,
      "Suscriptor": result.subscriber_name,
      "Nombre del Plan": result.plan_name,
      "Método de Pago": result.payment_method,
      "Estado": result.status,
      "Cuotas Pagadas": result.paid_installments,
      "% Ajuste Simple": result.simple_adjustment_percentage,
      "% Ajuste Doble": result.double_adjustment_percentage,
      "% Adecuación Simple": result.simple_adequation_percentage,
      "% Adecuación Doble": result.double_adequation_percentage,
      "Cuotas Pagadas Mensual": result.monthly_paid_installments,
      "Cuotas Puntaje": result.score_installments,
      "Cuotas Adj. Pagadas": result.adjudicated_installments,
      "Total Cuotas Pagadas": result.total_paid_installments,
      "Fecha Último Pago": result.last_payment_date,
      "Cuotas Vencidas": result.overdue_installments,
      "Deuda Mora Mensual": result.monthly_due_mora,
      "Cuotas a Vencer Mensual": result.monthly_due_installments,
      "Cuotas Adj. Restantes": result.remaining_adjudicated_installments,
      "Cuotas Adeudadas": result.due_installments,
      "Fecha Mora Más Antigua": result.oldest_due_date,
      "Monto Pagado Histórico": result.historical_paid_amount,
      "Deuda Mora": result.mora_debt,
      "Deuda Próxima": result.upcoming_debt,
      "Deuda Total a Cancelar": result.total_debt_to_cancel,
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "DebtBalances");

    XLSX.writeFile(workbook, "SaldosDeDeuda.xlsx");
  };

  const exportToPDF = () => {
    alert("Este reporte es demasiado extenso y no se encuentra disponible en formato PDF.");
  };

  if (!header || !results || results.length === 0) {
    return null;
  }

  const paginatedResults = results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        maxHeight: '90vh',
        bgcolor: 'background.paper',
        border: '1px solid #ccc',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        overflowY: 'auto',
        fontFamily: 'Arial, sans-serif',
      }}>
        <Button
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            minWidth: 'auto',
            padding: 0,
            color: '#555'
          }}
        >
          <CloseIcon />
        </Button>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={exportToExcel}
            startIcon={<DescriptionOutlinedIcon />}
          >
            Descargar
          </Button>
          <Button
            sx={{ marginLeft: 2 }}
            variant="contained"
            color="primary"
            onClick={exportToPDF}
            startIcon={<PrintIcon />}
          >
            Imprimir
          </Button>
        </Box>
        <Box
          sx={{
            mb: 3,
            textAlign: 'center',
            border: '1px solid',
            p: 2,
            borderRadius: 2,
            borderColor: '#4a90e2'
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: 'bold', color: '#333', mb: 1 }}
          >
            {header.constructora_info}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: '#555', fontStyle: 'italic', mb: 0.5 }}
          >
            Actualizado a la fecha: {header.updated_at_date}
          </Typography>
          <Typography variant="body2" sx={{ color: '#555' }}>
            {header.date_filter_text}
          </Typography>
        </Box>

        <Typography
          variant="body2"
          sx={{ textAlign: 'left', fontSize: '0.875rem', color: '#555', mt: 2 }}
        >
          {`Cantidad de contratos encontrados: ${results.length}`}
        </Typography>

        <TableContainer component={Paper} sx={{ border: '1px solid #ddd', borderRadius: 2, overflowX: 'auto', maxHeight: 400 }}>
          <Table aria-label="debt balances report" sx={{ minWidth: 'max-content' }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><strong>Grupo</strong></TableCell>
                <TableCell><strong>Número de Contrato</strong></TableCell>
                <TableCell><strong>Suscriptor</strong></TableCell>
                <TableCell><strong>Nombre del Plan</strong></TableCell>
                <TableCell><strong>Método de Pago</strong></TableCell>
                <TableCell><strong>Estado</strong></TableCell>
                <TableCell><strong>Cuotas Pagadas</strong></TableCell>
                <TableCell><strong>% Ajuste Simple</strong></TableCell>
                <TableCell><strong>% Ajuste Doble</strong></TableCell>
                <TableCell><strong>% Adecuación Simple</strong></TableCell>
                <TableCell><strong>% Adecuación Doble</strong></TableCell>
                <TableCell><strong>Cuotas Pagadas Mensual</strong></TableCell>
                <TableCell><strong>Cuotas Puntaje</strong></TableCell>
                <TableCell><strong>Cuotas Adj. Pagadas</strong></TableCell>
                <TableCell><strong>Total Cuotas Pagadas</strong></TableCell>
                <TableCell><strong>Fecha Último Pago</strong></TableCell>
                <TableCell><strong>Cuotas Vencidas</strong></TableCell>
                <TableCell><strong>Deuda Mora Mensual</strong></TableCell>
                <TableCell><strong>Cuotas a Vencer Mensual</strong></TableCell>
                <TableCell><strong>Cuotas Adj. Restantes</strong></TableCell>
                <TableCell><strong>Cuotas Adeudadas</strong></TableCell>
                <TableCell><strong>Fecha Mora Más Antigua</strong></TableCell>
                <TableCell><strong>Monto Pagado Histórico</strong></TableCell>
                <TableCell><strong>Deuda Mora</strong></TableCell>
                <TableCell><strong>Deuda Próxima</strong></TableCell>
                <TableCell><strong>Deuda Total a Cancelar</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedResults.map((result, index) => (
                <TableRow key={index}>
                  <TableCell>{result.group}</TableCell>
                  <TableCell>{result.order_number}</TableCell>
                  <TableCell>{result.subscriber_name}</TableCell>
                  <TableCell>{result.plan_name}</TableCell>
                  <TableCell>{result.payment_method}</TableCell>
                  <TableCell>{result.status}</TableCell>
                  <TableCell>{result.paid_installments}</TableCell>
                  <TableCell>{result.simple_adjustment_percentage}</TableCell>
                  <TableCell>{result.double_adjustment_percentage}</TableCell>
                  <TableCell>{result.simple_adequation_percentage}</TableCell>
                  <TableCell>{result.double_adequation_percentage}</TableCell>
                  <TableCell>{result.monthly_paid_installments}</TableCell>
                  <TableCell>{result.score_installments}</TableCell>
                  <TableCell>{result.adjudicated_installments}</TableCell>
                  <TableCell>{result.total_paid_installments}</TableCell>
                  <TableCell>{result.last_payment_date}</TableCell>
                  <TableCell>{result.overdue_installments}</TableCell>
                  <TableCell>{result.monthly_due_mora}</TableCell>
                  <TableCell>{result.monthly_due_installments}</TableCell>
                  <TableCell>{result.remaining_adjudicated_installments}</TableCell>
                  <TableCell>{result.due_installments}</TableCell>
                  <TableCell>{result.oldest_due_date}</TableCell>
                  <TableCell>$ {(result.historical_paid_amount ?? 0).toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                  <TableCell>$ {(result.mora_debt ?? 0).toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                  <TableCell>$ {(result.upcoming_debt ?? 0).toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                  <TableCell>$ {(result.total_debt_to_cancel ?? 0).toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          count={results.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Contratos por página"
          rowsPerPageOptions={[100, 200, 500]}
        />
      </Box>
    </Modal>
  );
};

export default DebtBalancesModal;
