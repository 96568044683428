import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import PrintIcon from "@mui/icons-material/Print";
import {
    Box,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Pagination,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRegularizesCoupons } from "../api/endpoints/regularizesPlans";
import {
    GeneralTableBox,
    GeneralTableContainer,
    HeadTableCell,
    PaginationBox,
    RowTableCell,
    SelectFormControl,
} from "../assets/styles/tables";

const ITEMS_PER_PAGE = 10; // Establece tu valor predeterminado para items por página

const RegularizePlanModal = ({ open, onClose, item }) => {
  const handlePrint = () => {
    const originalSize = document.body.style.zoom;
    document.body.style.zoom = '70%'; // Disminuye el zoom para ajustar el contenido en la impresión
    window.print();
    document.body.style.zoom = originalSize; // Restablece el zoom original después de la impresión
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          background: "white",
          padding: 3,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: 2,
          width: '90%', // Ancho automático para adaptarse al contenido
          overflow: 'hidden' // Oculta el overflow para evitar barras de desplazamiento innecesarias
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">CUOTAS REGULARIZADAS</Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={handlePrint}>
            <PrintIcon />
          </IconButton>
        </Box>
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="subtitle1">
            ORDEN NÚMERO: {item?.header?.contract_number}
          </Typography>
          <Typography variant="subtitle1">
            SUSCRIPTOR: {item?.header?.subscriber}
          </Typography>
        </Box>
        <GeneralTableContainer
          sx={{
            overflow: 'auto',
            maxWidth: '100%',
          }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Número Cuota</TableCell>
                <TableCell align="center">Fecha 1er Venc.</TableCell>
                <TableCell align="center">Importe 1er Venc.</TableCell>
                <TableCell align="center">Fecha 1ra Regulariz.</TableCell>
                <TableCell align="center">Importe 1ra Regulariz.</TableCell>
                <TableCell align="center">Fecha 2da Regulariz.</TableCell>
                <TableCell align="center">Importe 2da Regulariz.</TableCell>
                <TableCell align="center">Importe regularizado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {item?.coupons?.map((coupon, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{coupon.fee_number1}</TableCell>
                  <TableCell align="center">
                    {new Date(coupon.first_due_date).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="center">
                    $ {coupon.first_due_amount.toLocaleString()}
                  </TableCell>
                  <TableCell align="center">
                    {new Date(coupon.regulariza1_date).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="center">
                    $ {coupon.regulariza1_amount.toLocaleString()}
                  </TableCell>
                  <TableCell align="center">
                    $ {coupon.regulariza2_amount.toLocaleString()}
                  </TableCell>
                  <TableCell align="center">
                    {coupon.regulariza2_date === "none"
                      ? new Date(coupon.regulariza2_date).toLocaleDateString()
                      : "00/00/0000"}
                  </TableCell>
                  <TableCell align="center">
                    $ {coupon.regulariza1_amount.toLocaleString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </GeneralTableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 2,
            mr: 2,
          }}
        >
          <Typography variant="subtitle1" sx={{ marginRight: 2 }}>
            Cuotas regularizadas: {item?.header?.regularized_fees}
          </Typography>
          <Typography variant="subtitle1">
            Total regularizado: $ {item?.header?.total}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

const RegularizePlanHeader = ({ isOpen, setIsOpen, filter, setFilter }) => {
  const { plans } = useSelector((state) => state.plans);
  const { groups } = useSelector((state) => state.groups);

  // const [filter, setFilter] = useState({
  //   plan: plans.length > 0 ? plans[0].uuid : "", // Primer elemento de los planes como valor predeterminado
  //   group: "",
  //   order: "",
  // });

  const handleFilter = (event) => {
    setFilter({
      ...filter,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Box
      sx={{
        mt: 3,
        justifyContent: "center",
        backgroundColor: "#f2f2f2",
        borderRadius: "20px",
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
        padding: "15px",
        boxShadow: "none",
        borderBottom: "1px solid #ccc",
      }}
    >
      <Box
        sx={{
          fontSize: "25px",
          fontWeight: "bold",
        }}
      >
        Cupon | Cupones | Planes Regulariza
      </Box>
      <Box sx={{ ml: 0, mt: 2 }}>
        <SelectFormControl sx={{ ml: 2, mt: 0, width: "15%" }}>
          <InputLabel margin="dense" id="trimestre-label">
            Plan
          </InputLabel>
          <Select
            labelId="plan-label"
            id="plan-select"
            value={filter.plan}
            onChange={handleFilter}
            name="plan"
          >
            {plans.map((plan) => (
              <MenuItem key={plan.uuid} value={plan.uuid}>
                {plan.name}
              </MenuItem>
            ))}
          </Select>
        </SelectFormControl>
        <SelectFormControl sx={{ ml: 2, mt: 0, width: "15%" }}>
          <InputLabel margin="dense" id="group-label">
            Grupo
          </InputLabel>
          <Select
            labelId="group-label"
            id="group-select"
            value={filter.group}
            onChange={handleFilter}
            name="group"
          >
            <MenuItem value="">Todos</MenuItem>
            {groups.map((group) => (
              <MenuItem key={group.uuid} value={group.uuid}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </SelectFormControl>
        <SelectFormControl sx={{ ml: 2, mt: 0, width: "15%" }}>
          <TextField
            label="Orden"
            id="contract_number-input"
            value={filter.contract_number}
            onChange={handleFilter}
            name="contract_number"
          />
        </SelectFormControl>
      </Box>
    </Box>
  );
};

const RegularizePlanForm = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  // const filters = useSelector((state) => state.updatedDebt.filters);

  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const { plans } = useSelector((state) => state.plans);
  const [filter, setFilter] = useState({
    plan: plans.length > 0 ? plans[0].uuid : "", // Primer elemento de los planes como valor predeterminado
    group: "",
    contract_number: "",
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCoupons, setSelectedCoupons] = useState([]);

  const handleViewClick = (item) => {
    setSelectedCoupons(item);
    setModalOpen(true);
  };

  // Esta función se encargará de llamar al endpoint con los filtros y paginación correspondientes
  const fetchDataWithFilters = async () => {
    setIsLoading(true);
    try {
      const response = await dispatch(
        getRegularizesCoupons({
          plan: filter.plan,
          group: filter.group,
          contract_number: filter.contract_number,
          page: page,
          per_page: ITEMS_PER_PAGE,
        })
      );
      setData(response.payload.grouped_coupons);
      setTotalPages(response.payload.pages);
      setTotalCount(response.payload.total);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  // Llamamos a fetchDataWithFilters cuando el componente se monta y cuando cambian los filtros o el número de página
  useEffect(() => {
    fetchDataWithFilters();
  }, [filter, page]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mr: 15,
        ml: 5,
      }}
    >
      <RegularizePlanHeader filter={filter} setFilter={setFilter} />
      <GeneralTableBox
        sx={{
          m: 0,
          borderRadius: "0px",
          boxShadow: "none",
          "*::-webkit-scrollbar": {
            width: "8px",
            height: "8px",
          },
          "*::-webkit-scrollbar-track": {
            background: "inherit",
            boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: "gray",
            borderRadius: "20px",
          },
          "@media (min-width: 900px) and (max-width: 1536px)": {
            width: "100%",
          },
        }}
      >
        <GeneralTableContainer
          sx={{
            maxHeight: "45em",
            overflow: "auto",
          }}
        >
          <Table aria-label="plan table">
            <TableHead>
              <TableRow>
                <HeadTableCell sx={{ textAlign: "center" }}>
                  Orden
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center" }}>
                  Suscriptores
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center" }}>
                  Cuotas regulariz.
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center" }}>
                  Adecuaciones regulariz.
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center" }}>
                  Total regularizado
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center" }}>Ver</HeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, key) => (
                <TableRow key={key}>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {item.header.contract_number}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {item.header.subscriber}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {item.header.regularized_fees}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {item.header.regularized_adjustments}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {item.header.total}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    <IconButton onClick={() => handleViewClick(item)}>
                      <DocumentScannerIcon sx={{ color: "#5e894f" }} />
                    </IconButton>
                  </RowTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </GeneralTableContainer>
      </GeneralTableBox>
      <PaginationBox
        sx={{
          backgroundColor: theme.palette.background.formTables,
          mt: 0,
          borderBottomRightRadius: "20px",
          borderBottomLeftRadius: "20px",
        }}
      >
        <Pagination
          sx={{ mr: 10 }}
          count={totalPages} // Usamos totalPages directamente
          shape="rounded"
          page={page}
          onChange={(event, value) => setPage(value)}
        />
        <Box
          sx={{
            ml: 10,
            fontSize: "15px",
          }}
        >
          Registros: {totalCount}
        </Box>
      </PaginationBox>
      <RegularizePlanModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        item={selectedCoupons}
      />
    </Box>
  );
};

export default RegularizePlanForm;
