import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormControl,
  CircularProgress // Importa el spinner
} from "@mui/material";
import { getCouponsStatistics } from "../api/endpoints/statistics";

import FeesByOrderModal from "./statistics/FeesByOrderModal";
import DeferredCouponsModal from "./statistics/DeferredCouponsModal";
import IssuedCouponsModal from "./statistics/IssuedCouponsModal ";
import ScoreRankingModal from "./statistics/ScoreRankingModal";
import SalesReportModal from "./statistics/SalesReportModal";
import DebtBalancesModal from "./statistics/DebtBalancesModal";

const StatisticsForm = () => {
  const dispatch = useDispatch();
  const { branches } = useSelector((state) => state.branch); // Sucursales
  const { plans } = useSelector((state) => state.plans); // Planes
  const { groups } = useSelector((state) => state.groups); // Grupos
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false); // Estado para controlar el modal
  const [results, setResults] = useState([]); // Estado para almacenar los resultados
  const [header, setHeader] = useState([]); // Estado para almacenar los resultados

  const [filters, setFilters] = useState({
    dateStart: "",
    dateEnd: "",
    reportType: "not_selected",
    branch: branches.length > 0 ? branches[0].uuid : "",
    plan: "all",
    group: "all",
    orderNumber: "",
    detail: false,
    deferred: false,
    historical: false,
  });

  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFilters({
      ...filters,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // Llamar a la API con los filtros
      const response = await dispatch(getCouponsStatistics(filters));
      const { results, header } = response.payload; // Extrae 'results' y 'header' de la respuesta

      // Si es fees_by_order, abrir modal y pasar datos
      if (filters.reportType === "fees_by_order") {
        setResults(results);  // Guarda los resultados
        setHeader(header);    // Guarda el header
        setModalOpen(true);   // Abre el modal
      }

      // Si es deferred_coupons, abrir modal y pasar datos
      if (filters.reportType === "deferred_coupons") {
        setResults(results);  // Guarda los resultados
        setHeader(header);    // Guarda el header
        setModalOpen(true);   // Abre el modal de DeferredCoupons
      }

      // Si es issued_coupons, abrir modal y pasar datos
      if (filters.reportType === "issued_coupons") {
        setResults(results);  // Guarda los resultados
        setHeader(header);    // Guarda el header
        setModalOpen(true);   // Abre el modal de DeferredCoupons
      }

      // Si es issued_coupons, abrir modal y pasar datos
      if (filters.reportType === "score_ranking") {
        setResults(results);  // Guarda los resultados
        setHeader(header);    // Guarda el header
        setModalOpen(true);   // Abre el modal de DeferredCoupons
      }

      // Si es issued_coupons, abrir modal y pasar datos
      if (filters.reportType === "sales") {
        setResults(results);  // Guarda los resultados
        setHeader(header);    // Guarda el header
        setModalOpen(true);   // Abre el modal de DeferredCoupons
      }

      if (filters.reportType === "debt_balances") {
        setResults(results);  // Guarda los resultados
        setHeader(header);    // Guarda el header
        setModalOpen(true);   // Abre el modal de DeferredCoupons
      }

      setLoading(false);
    } catch (err) {
      setError("Error al obtener los datos.");
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        mt: 3,
        justifyContent: "center",
        backgroundColor: "#f2f2f2",
        borderRadius: "20px",
        padding: "15px",
        boxShadow: "none",
        border: "1px solid #ccc",
        width: "99%",
      }}
    >
      <Typography variant="h6" align="center" sx={{ mb: 3 }}>
        Estadísticas
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <TextField
            label="Fecha Desde"
            type="date"
            name="dateStart"
            value={filters.dateStart}
            onChange={handleFilterChange}
            InputLabelProps={{ shrink: true }}
            sx={{ width: "20%" }}
          />
          <TextField
            label="Fecha Hasta"
            type="date"
            name="dateEnd"
            value={filters.dateEnd}
            onChange={handleFilterChange}
            InputLabelProps={{ shrink: true }}
            sx={{ width: "20%" }}
          />
          {filters.reportType === "debt_balances" && (
            <TextField
              label="Cuota Pura Actual"
              name="cuota_pura"
              value={filters.cuota_pura || ""}
              onChange={handleFilterChange}
              sx={{ width: "30%" }}
            />
          )}
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <FormControl sx={{ width: "30%" }}>
            <InputLabel id="branch-label">Sucursal</InputLabel>
            <Select
              labelId="branch-label"
              name="branch"
              value={filters.branch}
              onChange={handleFilterChange}
              label="Sucursales"
            >
              {branches.map((branch) => (
                <MenuItem key={branch.uuid} value={branch.uuid}>
                  {branch.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: "30%" }}>
            <InputLabel id="plan-label">Plan</InputLabel>
            <Select
              labelId="plan-label"
              name="plan"
              value={filters.plan || "all"}
              onChange={handleFilterChange}
              label="Plan"
            >
              <MenuItem value="all">TODOS</MenuItem>
              {plans.map((plan) => (
                <MenuItem key={plan.uuid} value={plan.uuid}>
                  {plan.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: "30%" }}>
            <InputLabel id="group-label">Grupo</InputLabel>
            <Select
              labelId="group-label"
              name="group"
              value={filters.group || "all"}
              onChange={handleFilterChange}
              label="Grupo"
            >
              <MenuItem value="all">TODOS</MenuItem>
              {groups.map((group) => (
                <MenuItem key={group.uuid} value={group.uuid}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <TextField
            label="Orden"
            name="orderNumber"
            value={filters.orderNumber}
            onChange={handleFilterChange}
            sx={{ width: "30%" }}
          />
          <FormControl sx={{ width: "30%" }}>
            <InputLabel id="font-size-label">Tamaño Fuente</InputLabel>
            <Select
              labelId="font-size-label"
              name="fontSize"
              value={filters.fontSize || "9XP"}
              onChange={handleFilterChange}
              label="Tamaño Fuente"
            >
              <MenuItem value="9XP">9XP</MenuItem>
              <MenuItem value="10XP">10XP</MenuItem>
              <MenuItem value="12XP">12XP</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ width: "30%" }}>
            <InputLabel id="report-type-label">Tipo de reporte</InputLabel>
            <Select
              labelId="report-type-label"
              name="reportType"
              value={filters.reportType || "not_selected"}
              onChange={handleFilterChange}
              label="Tipo de reporte"
            >
              <MenuItem value="not_selected">Seleccione uno</MenuItem>
              <MenuItem value="fees_by_order">Cuotas por órden</MenuItem>
              <MenuItem value="deferred_coupons">Cupones diferidos</MenuItem>
              <MenuItem value="issued_coupons">Cupones emitidos</MenuItem>
              <MenuItem value="score_ranking">Ranking de puntajes</MenuItem>
              <MenuItem value="debt_balances">Saldos de deuda</MenuItem>
              <MenuItem value="sales">Ventas</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {/* <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
          <Box>
            <Checkbox
              name="detail"
              checked={filters.detail}
              onChange={handleFilterChange}
              disabled={filters.reportType !== "fees_by_order"}
            />
            <Typography variant="body1" component="span">
              Detalle
            </Typography>
          </Box>
          <Box>
            <Checkbox
              name="deferred"
              checked={filters.deferred}
              onChange={handleFilterChange}
              disabled={filters.reportType !== "fees_by_order"}
            />
            <Typography variant="body1" component="span">
              Diferidas
            </Typography>
          </Box>
          <Box>
            <Checkbox
              name="historical"
              checked={filters.historical}
              onChange={handleFilterChange}
              disabled={filters.reportType !== "fees_by_order"}
            />
            <Typography variant="body1" component="span">
              Histórico
            </Typography>
          </Box>
        </Box> */}
        {error && <Typography color="error">{error}</Typography>}
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={filters.reportType === "not_selected" || loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Ver Reporte"
            )}
          </Button>
        </Box>
      </Box>
      {filters.reportType === "fees_by_order" && modalOpen && (
        <FeesByOrderModal
          open={modalOpen}
          handleClose={handleCloseModal}
          header={header} // Pasa el header al modal
          results={results} // Pasa los resultados al modal
        />
      )}
      {filters.reportType === "deferred_coupons" && modalOpen && (
        <DeferredCouponsModal
          open={modalOpen}
          handleClose={handleCloseModal}
          header={header} // Pasa el header al modal
          results={results} // Pasa los resultados al modal
        />
      )}
      {filters.reportType === "issued_coupons" && modalOpen && (
        <IssuedCouponsModal
          open={modalOpen}
          handleClose={handleCloseModal}
          header={header} // Pasa el header al modal
          results={results} // Pasa los resultados al modal
        />
      )}
      {filters.reportType === "score_ranking" && modalOpen && (
        <ScoreRankingModal
          open={modalOpen}
          handleClose={handleCloseModal}
          header={header} // Pasa el header al modal
          results={results} // Pasa los resultados al modal
        />
      )}

      {filters.reportType === "sales" && modalOpen && (
        <SalesReportModal
          open={modalOpen}
          handleClose={handleCloseModal}
          header={header} // Pasa el header al modal
          results={results} // Pasa los resultados al modal
        />
      )}

      {filters.reportType === "debt_balances" && modalOpen && (
        <DebtBalancesModal
          open={modalOpen}
          handleClose={handleCloseModal}
          header={header} // Pasa el header al modal
          results={results} // Pasa los resultados al modal
        />
      )}
    </Box>
  );
};

export default StatisticsForm;