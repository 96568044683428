import BuildCircleOutlinedIcon from "@mui/icons-material/BuildCircleOutlined";

import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentIcon from "@mui/icons-material/Assignment";
import ConstructionIcon from "@mui/icons-material/Construction";
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import PeopleIcon from "@mui/icons-material/People";
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import BarChartIcon from '@mui/icons-material/BarChart';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from "@mui/icons-material/Settings";
import packageJson from '../../package.json';


const menuItems = [
  {
    label: "Plan",
    icon: <AssessmentIcon />,
    children: [
      {
        label: "Ver Planes",
        path: "plan/ver-planes",
      },
    ],
  },
  {
    label: "Contrato",
    icon: <AssignmentIcon />,
    children: [
      {
        label: "Ver Contratos",
        path: "/contratos",
      },
      {
        label: "Seguro de Vida",
        children: [
          {
            label: "Exportar",
            path: "/contrato/seguro-vida/exportar",
          },
          {
            label: "Importar",
            path: "/contrato/seguro-vida/importar",
          },
        ],
      },
      {
        label: "Seguro de incendio",
        children: [
          {
            label: "Exportar",
            path: "/contrato/seguro-incendio/exportar",
          },
        ],
      },
    ],
  },
  // {
  //   label: "Obra",
  //   icon: <ConstructionIcon />,
  //   children: [
  //     {
  //       label: "Adicionales",
  //       children: [
  //         {
  //           label: "Mant. adicionales",
  //           path: "/obra/adicionales/mant-adicionales",
  //         },
  //         {
  //           label: "Mant. presupuestos",
  //           path: "/obra/adicionales/mant-presupuestos",
  //         },
  //         {
  //           label: "Mant. coeficientes",
  //           path: "/obra/adicionales/coeficientes",
  //         },
  //       ],
  //     },
  //     {
  //       label: "Adicionales en obra",
  //       path: "/obra/adicionales-obra",
  //     },
  //     {
  //       label: "Articulos",
  //       children: [
  //         {
  //           label: "Mantenedor",
  //           path: "/obra/articulos/mantenedor",
  //         },
  //         {
  //           label: "Rubros",
  //           path: "/obra/articulos/rubros",
  //         },
  //         {
  //           label: "Grupos",
  //           path: "/obra/articulos/grupos",
  //         },
  //         {
  //           label: "Marcas",
  //           path: "/obra/articulos/marcas",
  //         },
  //         {
  //           label: "Colores",
  //           path: "/obra/articulos/colores",
  //         },
  //         {
  //           label: "Otras especificaciones",
  //           path: "/obra/articulos/otras-especificaciones",
  //         },
  //       ],
  //     },
  //     {
  //       label: "Movimientos de Materiales",
  //       children: [
  //         {
  //           label: "Pedidos a proovedores",
  //           path: "/obra/movimientos-materiales/pedidos-proovedores",
  //         },
  //         {
  //           label: "Traslados entre dep.",
  //           path: "/obra/movimientos-materiales/traslados-dep",
  //         },
  //       ],
  //     },
  //     {
  //       label: "Unid. de consumo",
  //       children: [
  //         {
  //           label: "Asignar materiales",
  //           path: "/obra/unid-consumo/asignar-materiales",
  //         },
  //         {
  //           label: "Barrios",
  //           path: "/obra/unid-consumo/barrios",
  //         },
  //         {
  //           label: "Unidades de consumo",
  //           path: "/obra/unid-consumo/editar",
  //         },
  //       ],
  //     },
  //     {
  //       label: "Autorizados",
  //       path: "/obra/autorizados",
  //     },
  //     {
  //       label: "Almacenes",
  //       path: "/obra/almacenes",
  //     },
  //     {
  //       label: "Auditorias",
  //       path: "/obra/auditorias",
  //     },
  //     {
  //       label: "Salida sobrantes",
  //       path: "/obra/salida-sobrantes",
  //     },
  //     {
  //       label: "Gestión de máquinas",
  //       children: [
  //         {
  //           label: "Pedidos",
  //           path: "/obra/gestion-maquinas/pedidos",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   label: "Lotes",
  //   icon: <HolidayVillageIcon  />,
  //   children: [
  //     {
  //       label: "Pueblo nuestro 2",
  //       path: "/lotes/pueblo-nuestro-2",
  //     },
  //     {
  //       label: "Pueblo nuestro 3",
  //       path: "/lotes/pueblo-nuestro-3",
  //     },
  //     {
  //       label: "Cargas",
  //       children: [
  //         {
  //           label: "Subcontratistas",
  //           path: "/cargas/subcontratistas",
  //         },
  //         {
  //           label: "Personas",
  //           path: "/cargas/personas",
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    label: "Entidades",
    icon: <PeopleIcon />,
    children: [
      {
        label: "Suscriptores",
        path: "/entidades/suscriptores",
      },
      {
        label: "Personas juridicas",
        children: [
          {
            label: "Ver personas juridicas",
            path: "/entidades/personas-juridicas",
          },
        ],
      },
    ],
  },
  {
    label: "Cupon",
    icon: <ConfirmationNumberIcon />,
    children: [
      {
        label: "Generar",
        children: [
          {
            label: "Generar cupones",
            path: "/cupon/generar/generar-cupones",
          },
          {
            label: "Control de generación",
            path: "/cupon/generar/control-generacion",
          },
        ],
      },
      {
        label: "Registrar",
        children: [
          {
            label: "Registar cobro",
            path: "/cupon/registrar/registrar-cobro",
          },
          {
            label: "Cobros externos",
            path: "/cupon/registrar/cobros-externos",
          },
          {
            label: "Ver cobros",
            path: "/cupon/registrar/ver-cobros",
          },
        ],
      },
      {
        label: "Cupones",
        children: [
          {
            label: "Ver cupones",
            path: "/cupon/cupones/",
          },
          // {
          //   label: "Ver ranking de puntajes",
          //   path: "/cupon/cupones/ranking-puntajes",
          // },
          // {
          //   label: "Ver adjudicadas",
          //   path: "/cupon/cupones/adjudicadas",
          // },
          {
            label: "Actualizar deuda",
            path: "/cupon/cupones/actualizar-deuda",
          },
          {
            label: "Planes regulariza",
            path: "/cupon/cupones/planes-regulariza",
          },
        ],
        
      },
      {
        label: "Impresión",
        children: [
          // {
          //   label: "Impresión masiva",
          //   path: "/cupon/impresion/masiva",
          // },
          {
            label: "Cupones por mail",
            path: "/cupon/impresion/cupones-mail",
          },
        ],
      },
    ],
  },
  {
    label: "Estadisticas",
    icon: <BarChartIcon />,
    children: [
      // {
      //   label: "Facturación",
      //   children: [
      //     {
      //       label: "Ventas",
      //       path: "/estadisticas/facturacion/ventas",
      //     },
      //     {
      //       label: "Exportar CITI",
      //       path: "/estadisticas/facturacion/exportar-citi",
      //     },
      //   ],
      // },
      {
        label: "Cupones",
        path: "/cupon/estadisticas",
      },
      {
        label: "Facturas",
        path: "/cupon/estadisticas",
      },
  //     {
  //       label: "Suscriptores",
  //       children: [
  //         {
  //           label: "Suscriptores por contrato",
  //           path: "/estadisticas/suscriptores/suscriptores-contrato",
  //         },
  //         {
  //           label: "Informe de datos",
  //           path: "/estadisticas/suscriptores/informe-datos",
  //         },
  //         {
  //           label: "Informe de morosos",
  //           path: "/estadisticas/suscriptores/informe-morosos",
  //         },
  //       ],
  //     },
  //     {
  //       label: "Cobranzas",
  //       children: [
  //         {
  //           label: "Ver cobranzas",
  //           path: "/estadisticas/cobranzas/ver",
  //         },
  //         {
  //           label: "Resumen cobranzas",
  //           path: "/estadisticas/cobranzas/resumen",
  //         },
  //         {
  //           label: "Ver cobranzas detelle",
  //           path: "/estadisticas/cobranzas/ver-detalle",
  //         },
  //       ],
  //     },
  //     {
  //       label: "Datos de plan",
  //       children: [
  //         {
  //           label: "Informe de contratos",
  //           path: "/estadisticas/datos-plan/informe-contratos",
  //         },
  //         {
  //           label: "Informe de adjudic.",
  //           path: "/estadisticas/datos-plan/informe-adjudica",
  //         },
  //         {
  //           label: "Informe de saldo deuda",
  //           path: "/estadisticas/datos-plan/informe-saldo-deuda",
  //         },
  //         {
  //           label: "Informe de pagos",
  //           path: "/estadisticas/datos-plan/informe-pagos",
  //         },
  //       ],
  //     },
  //     {
  //       label: "Obra",
  //       children: [
  //         {
  //           label: "Informe de análisis",
  //           path: "/estadisticas/obra/informe-analisis",
  //         },
  //       ],
  //     },
    ],
  },
  // {
  //   label: "Reclamos",
  //   icon: <ReceiptIcon />,
  //   children: [
  //     {
  //       label: "Mantenedor",
  //       path: "/reclamos/mantenedor",
  //     },
  //   ],
  // },
  {
    label: "Administración",
    icon: <SettingsIcon />,
    children: [
      {
        label: "Usuarios",
        children: [
          {
            label: "Usuarios",
            path: "/administracion/usuarios/",
          },
          {
            label: "Perfiles",
            path: "/administracion/usuarios/perfiles/",
          },
        ],
      },
      {
        label: "Empresa",
        children: [
          {
            label: "Sucursales",
            path: "/administracion/empresa/sucursales",
          },
        ],
      },
    ],
  },
  {  
    label: `v${packageJson.version}`,
  },
];

export default menuItems;


