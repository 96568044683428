import { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import imagen from "../assets/img/colonialLogin.jpg";
import logo from "../assets/img/logo_constructora.png";
import { login } from "../api/endpoints/auth";
import { setToken } from "../features/auth/authSlice";
import { setUserLogged } from "../features/auth/userLoggedSlice";
import { addBranch } from "../features/branchs/branchSlice";
import { useDispatch, useSelector } from "react-redux";
import { afip } from "../api/endpoints/afip";

const validationSchema = Yup.object().shape({
  usuario: Yup.string().required("Este campo es requerido"),
  password: Yup.string()
    .required("Este campo es requerido")
    .min(6, "La contraseña debe tener al menos 6 caracteres"),
});

const initialValues = {
  usuario: "",
  password: "",
};

const LoginForm = (props) => {
  const isSmallScreen = useMediaQuery("(max-width:766px)");
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [sucursales, setSucursales] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const afipState = useSelector((state) => state.afip);

  const handleSubmit = async (e) => {
    try {
      const result = await dispatch(
        login({
          email: e.usuario,
          password: e.password,
        })
      ).unwrap();
      if (result.status === 200) {
        const { user } = result.data;
        const testExpirationTime = Date.now() + 120000; // 2 minutos en milisegundos
        dispatch(
          setToken({
            token: result.data.jwt,
            expirationTime: Date.now() + result.data.expiresIn * 1000,
            //expirationTime: testExpirationTime,
          })
        );
        dispatch(setUserLogged(user));
        await dispatch(afip());
        setIsLoggedIn(true);
        setSucursales(
          user.branch.map((branch) => ({
            value: branch.description,
            label: branch.description,
          }))
        );
        if (user.branch.length === 1) {
          setSelectedBranch(user.branch[0]);
        }
      }
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.error &&
        e.response.data.error.message
      ) {
        setErrorMessage(e.response.data.error.message);
      } else {
        setErrorMessage("Ocurrió un error inesperado al iniciar sesión");
      }
    }
  };

  const handleBranchChange = (event) => {
    setSelectedBranch(event.target.value);
  };

  useEffect(() => {
    if (selectedBranch) {
      dispatch(addBranch(selectedBranch));
      //props.onLogin();
    }
  }, [selectedBranch, dispatch]);

  return (
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        height: "100vh",
        marginTop: isSmallScreen ? "15%" : "3%",
      }}
    >
      <img src={logo} alt="" style={{ width: isSmallScreen ? "60%" : "30%" }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "2%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            boxShadow: "10px 10px 50px rgba(1,2,10,0.2)",
            borderRadius: "20px",
            backgroundColor: "#f2f2f2",
            border: "2px solid rgba(255,255,255,0.2)",
          }}
        >
          <Box
            sx={{
              display: isSmallScreen ? "none" : "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: 0,
              borderRadius: "20px",
              width: "340px",
              height: "450px",
            }}
          >
            <img
              src={imagen}
              alt=""
              style={{
                width: "340px",
                height: "450px",
                borderRadius: "20px 0 0 20px",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: 0,
              borderRadius: 10,
              width: isSmallScreen ? "320px" : "340px",
              height: isSmallScreen ? "450px" : "450px",
              p: isSmallScreen ? "10px 10px 0px" : 4,
              backgroundColor: "#f2f2f2",
            }}
          >
            <h1 style={{ marginTop: -15, marginBottom: 10 }}>
              Iniciar sesión{" "}
            </h1>
            <Box sx={{ maxWidth: 500, mb: 0 }}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched }) => (
                  <Form>
                    <Field name="usuario">
                      {({ field }) => (
                        <TextField
                          {...field}
                          label="Usuario"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          error={touched.usuario && Boolean(errors.usuario)}
                          helperText={touched.usuario && errors.usuario}
                          onChange={(event) => {
                            field.onChange(event);
                            setErrorMessage("");
                          }}
                        />
                      )}
                    </Field>
                    <Field name="password">
                      {({ field }) => (
                        <TextField
                          {...field}
                          label="Contraseña"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          type="password"
                          error={touched.password && Boolean(errors.password)}
                          helperText={touched.password && errors.password}
                          onChange={(event) => {
                            field.onChange(event);
                            setErrorMessage("");
                          }}
                        />
                      )}
                    </Field>
                    {isLoggedIn && sucursales.length > 1 && (
                      <FormControl
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        error={touched.sucursal && Boolean(errors.sucursal)}
                      >
                        <InputLabel id="sucursal-label">Sucursal</InputLabel>
                        <Field
                          as={Select}
                          labelId="sucursal-label"
                          name="sucursal"
                          label="Sucursal"
                          value={selectedBranch || ""}
                          onChange={handleBranchChange}
                        >
                          {sucursales.map((option, key) => (
                            <MenuItem key={key} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Field>
                        {touched.sucursal && errors.sucursal && (
                          <Box sx={{ color: "red" }}>{errors.sucursal}</Box>
                        )}
                      </FormControl>
                    )}
                    {errorMessage && (
                      <Box sx={{ color: "red", marginBottom: "10px" }}>
                        {errorMessage}
                      </Box>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                      style={{ marginTop: "5%", height: "50px" }}
                      disabled={
                        isLoggedIn && sucursales.length > 1 && !selectedBranch
                      }
                    >
                      {isLoggedIn && selectedBranch ? "Ingresar" : "Verificar"}
                    </Button>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginForm;
