import React from 'react';
import { Modal, Box, Typography, Grid, Card, CardContent, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';

const ScoreRankingModal = ({ open, handleClose, header, results }) => {

  // Función para exportar a Excel
  const exportToExcel = () => {
    const data = Object.keys(results).flatMap(category =>
      Object.keys(results[category]).flatMap(group =>
        results[category][group].map(contract => ({
          "Categoría": category,
          "Grupo": group, // Nombre del grupo
          "Número de Contrato": contract.contract_number,
          "Suscriptor": contract.subscriber_name,
          "Puntaje Total": contract.total_score,
          "Posición": contract.position,
        }))
      )
    );

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "RankingDePuntajes");

    XLSX.writeFile(workbook, "RankingDePuntajes.xlsx");
  };

  // Función para exportar a PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    let yOffset = 10;

    doc.setFontSize(12);
    doc.text("Reporte de ranking de puntajes", 105, yOffset, { align: 'center' });
    yOffset += 10;

    // Iterar por categorías y grupos
    Object.keys(results).forEach(category => {
      doc.setFont("helvetica", "bold");
      doc.text(`Categoría: ${category}`, 10, yOffset);
      yOffset += 5;

      Object.keys(results[category]).forEach(group => {
        doc.setFont("helvetica", "normal");
        doc.text(`Grupo: ${group}`, 10, yOffset);
        yOffset += 5;

        results[category][group].forEach(result => {
          doc.setFontSize(9);
          doc.text(`Contrato: ${result.contract_number}`, 10, yOffset);
          doc.text(`Suscriptor: ${result.subscriber_name}`, 40, yOffset);
          doc.text(`Puntaje: ${result.total_score}`, 140, yOffset);
          doc.text(`Posición: ${result.position}`, 180, yOffset);
          yOffset += 10;

          if (yOffset > 270) {
            doc.addPage();
            yOffset = 10;
          }
        });
      });
    });

    doc.save('RankingDePuntajes.pdf');
  };

  // Si no hay header o resultados, no mostrar nada
  if (!header || !results || Object.keys(results).length === 0) {
    return null;
  }

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        maxHeight: '90vh',
        bgcolor: 'background.paper',
        border: '1px solid #ccc',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        overflowY: 'auto',
        fontFamily: 'Arial, sans-serif',
      }}>
        <Button
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            minWidth: 'auto',
            padding: 0,
            color: '#555'
          }}
        >
          <CloseIcon />
        </Button>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={exportToExcel}
            startIcon={<DescriptionOutlinedIcon />}
          >
            Descargar
          </Button>
          <Button
            sx={{ marginLeft: 2 }}
            variant="contained"
            color="primary"
            onClick={exportToPDF}
            startIcon={<PrintIcon />}
          >
            Imprimir
          </Button>
        </Box>
        <Box
          sx={{
            mb: 3,
            textAlign: 'center',
            border: '1px solid',
            p: 2,
            borderRadius: 2,
            borderColor: '#4a90e2'
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: 'bold', color: '#333', mb: 1 }}
          >
            {header.constructora_info}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: '#555', fontStyle: 'italic', mb: 0.5 }}
          >
            Actualizado a la fecha: {header.updated_at_date}
          </Typography>
        </Box>

        <Typography variant="h5" component="h2" sx={{ mb: 3, textAlign: 'center', fontWeight: 'bold', color: '#4a90e2' }}>
          Ranking de Puntajes
        </Typography>

        <Grid container spacing={2}>
          {Object.keys(results).map((category) => (
            Object.keys(results[category]).map((group) => (
              <Grid item xs={12} md={6} key={`${category}-${group}`}>
                <Card sx={{ border: '1px solid #ddd', borderRadius: 2, maxHeight: '350px', overflowY: 'auto' }}>
                  <CardContent>
                    <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                      Categoría: {category}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom sx={{ fontWeight: 'bold', color: '#555' }}>
                      Grupo: {group}
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table size="small" aria-label="ranking table">
                        <TableHead>
                          <TableRow>
                            <TableCell><strong>Posición</strong></TableCell>
                            <TableCell><strong>Órden</strong></TableCell>
                            <TableCell><strong>Suscriptor</strong></TableCell>
                            <TableCell><strong>Puntaje</strong></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {results[category][group].map((contract, index) => (
                            <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#ffffff' }}>
                              <TableCell>{contract.position}</TableCell>
                              <TableCell>{contract.contract_number}</TableCell>
                              <TableCell>{contract.subscriber_name}</TableCell>
                              <TableCell>{contract.total_score}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ))}
        </Grid>
      </Box>
    </Modal>
  );
};

export default ScoreRankingModal;