import React from "react";

import EditIcon from "@mui/icons-material/Edit";
import {
    IconButton,
    Pagination,
    Table,
    TableBody,
    TableHead,
    TableRow,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
    EditButton,
    GeneralTableBox,
    GeneralTableContainer,
    HeadTableCell,
    PaginationBox,
    RowTableCell
} from "../../assets/styles/tables";

const ContractsEditTable = (props) => {
  const loadingContracts = useSelector(
    (state) => state.contracts.loadingContracts
  );

  const {
    contractsDataTable,
    handleEdit,
    handleDelete,
    getCurrentPageItems,
    ITEMS_PER_PAGE,
    page,
    setPage,
  } = props;

  const { plans } = useSelector((state) => state.plans);
  const { groups } = useSelector((state) => state.groups);

  return (
    <GeneralTableBox>
        {/* {loadingContracts ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Loading />
          </div>
        ) : (
          <> */}
      <GeneralTableContainer>
            <Table aria-label="contract table">
              <TableHead>
                <TableRow>
                  <HeadTableCell sx={{ minWidth: "100px" }}>N°</HeadTableCell>
                  <HeadTableCell sx={{ minWidth: "220px" }}>
                    Suscriptores
                  </HeadTableCell>
                  <HeadTableCell sx={{ minWidth: "100px" }}>
                    Fecha Suscripción
                  </HeadTableCell>
                  <HeadTableCell sx={{ minWidth: "150px" }}>Plan</HeadTableCell>
                  <HeadTableCell
                    sx={{ textAlign: "center", minWidth: "130px" }}
                  >
                    {" "}
                    Grupo{" "}
                  </HeadTableCell>
                  <HeadTableCell
                    sx={{ textAlign: "center", minWidth: "120px" }}
                  >
                    {" "}
                    Ult. cta emit.{" "}
                  </HeadTableCell>
                  <HeadTableCell
                    sx={{ textAlign: "center", minWidth: "100px" }}
                  >
                    {" "}
                    Acciones{" "}
                  </HeadTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getCurrentPageItems().map((contract, key) => (
                  <TableRow key={key}>
                    <RowTableCell>{contract.contract_number}</RowTableCell>
                    <RowTableCell>
                      {contract.subscribers.length > 0
                        ? contract.subscribers.map((subscriber, index) => (
                            <React.Fragment key={subscriber.subscriber.id}>
                              {subscriber.subscriber.last_name},{" "}
                              {subscriber.subscriber.first_name}
                              {index < contract.subscribers.length - 1 && ", "}
                            </React.Fragment>
                          ))
                        : "SIN SUSCRIPTOR"}
                    </RowTableCell>

                    <RowTableCell>
                      {contract.initial_subscription_date &&
                      !isNaN(new Date(contract.initial_subscription_date)) ? (
                        <>
                          {new Date(
                            contract.initial_subscription_date
                          ).toLocaleDateString("es-AR", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}
                        </>
                      ) : (
                        "-"
                      )}
                    </RowTableCell>

                    <RowTableCell>
                      {plans.find((plan) => plan.uuid === contract.uuid_plan)
                        ?.name || "-"}
                    </RowTableCell>

                    <RowTableCell>
                      {groups.find(
                        (group) => group.uuid === contract.uuid_group
                      )?.name || "-"}
                    </RowTableCell>
                    <RowTableCell sx={{ textAlign: "center" }}>
                      {contract.last_generated_fee || "0"}
                    </RowTableCell>
                    <RowTableCell sx={{ textAlign: "center" }}>
                      <EditButton
                        variant="contained"
                        onClick={() => {
                          handleEdit(contract);
                        }}
                      >
                        <IconButton size="small">
                          <EditIcon sx={{ color: "whitesmoke" }} />
                        </IconButton>
                      </EditButton>
                    </RowTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
        <PaginationBox>
          <Pagination
            count={Math.ceil(contractsDataTable.length / ITEMS_PER_PAGE)}
            shape="rounded"
            page={page}
            onChange={(event, value) => setPage(value)}
            />
        </PaginationBox>
      </GeneralTableContainer>
            {/* </>
          )} */}
    </GeneralTableBox>
  );
};

export default ContractsEditTable;
